import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CommunicatorV2Service } from '../../api/communicatorV2.service';
import { InfoDataService } from '../../info/info-data.service';
import { FirebaseMessaging, Notification } from '@capacitor-firebase/messaging';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

type PushNotificationHandler = (notification: Notification) => void;

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private currentToken: string | undefined;
  private handlers: Record<string, Array<PushNotificationHandler>> = {};

  constructor(
    private comV2: CommunicatorV2Service,
    private info: InfoDataService,
    private router: Router,
  ) {}

  public initPushNotifications(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!Capacitor.isNativePlatform()) {
        resolve();
        return;
      }
      this.registerPushNotifications()
        .then(() => resolve())
        .catch(reject);
    });
  }

  public async registerToken(): Promise<void> {
    let deviceType = Capacitor.getPlatform().toUpperCase();

    const { token } = await FirebaseMessaging.getToken();
    if (token === this.currentToken) {
      return;
    } else {
      this.currentToken = token;
    }
    await lastValueFrom(this.comV2.pushNotificationRegister(token, deviceType));
  }

  public async registerPushNotifications() {
    try {
      if (!Capacitor.isNativePlatform()) {
        throw new Error(
          'can\'t register push notifications on a non native platform',
        );
      }

      await this.addListeners();

      let permStatus = await FirebaseMessaging.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await FirebaseMessaging.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await this.registerToken();
    } catch (error) {
      console.error('Failed to register push notifications: ', error);
    }
  }

  public async hasNotificationPermission(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      return false;
    }
    const permStatus = await FirebaseMessaging.checkPermissions();
    return permStatus.receive === 'granted';
  }

  public addListener(type: string, handler: PushNotificationHandler) {
    if (!this.handlers[type]) this.handlers[type] = [];
    this.handlers[type].push(handler);
  }

  private async addListeners() {
    FirebaseMessaging.addListener('notificationReceived', (event) => {
      const notification = event.notification;
      console.log('notification received:' + JSON.stringify(notification));
      if (!notification) {
        console.error(
          'Got notification received event, but the content was empty',
        );
        return;
      }

      console.log('notification received:' + JSON.stringify(notification));

      const data: any = notification.data;

      if ('notification_uid' in data) {
        this.comV2.pushNotificationReceived(data.notification_uid).subscribe({
          next: (res) => {
            console.log('Notified server that message was received:', res);
          },
          error: (err) => {
            console.error(
              'Error while sending received notification to server:' +
              JSON.stringify(err),
            );
          },
        });
      }

      const notificationType = data.type;

      if (this.handlers[notificationType]) {
        for (const handler of this.handlers[notificationType]) {
          handler(notification);
        }
      }
    });

    FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (notification) => {
        if (!notification) {
          console.error('no notification received');
          return;
        }

        console.log('notification received 2:', notification);
        const data: any = notification.notification.data;

        if ('notification_uid' in data) {
          this.comV2.pushNotificationReceived(data.notification_uid).subscribe({
            next: (res) => {
              console.log('notification received by action:', res);
            },
            error: (err) => {
              console.error(
                'Error while sending received notification to server:' +
                JSON.stringify(err),
              );
            },
          });

          this.comV2.pushNotificationClicked(data.notification_uid).subscribe({
            next: (res) => {
              console.log('notification clicked:', res);
              this.info.clearAllCaches();
            },
            error: (err) => {
              console.error(
                'Error while sending clicked notification to server:' +
                JSON.stringify(err),
              );
            },
          });
        }

        if (data?.resource_url) {
          this.router.navigateByUrl(this.getAppUrlFromResourceUrl(data.resource_url));
        } else if (data?.aps?.alert?.title?.includes('SOS')) {
          console.log('opening SOS', data);
          this.router.navigateByUrl('/settings/sos');
        } else {
          console.log('notification data:', data)
        }

        const notificationType = data.type;
        if (this.handlers[notificationType]) {
          for (const handler of this.handlers[notificationType]) {
            handler(notification.notification);
          }
        }
      },
    );
  }

  getAppUrlFromResourceUrl(url: string): string {
    if (url.startsWith('reportapp://')) {
      return url.replace('reportapp://', '/');
    }
    return url;
  }
}
