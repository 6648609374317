/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportResponseTimes } from './reportResponseTimes';
import { ReportsPerCounselor } from './reportsPerCounselor';


export interface IntegrityReportsStatistics { 
    /**
     * Number of integrity reports created in the requested period
     */
    totalReports: number;
    /**
     * Total number of integrity reports created in the history of the app
     */
    totalReportsAllTime: number;
    responseTime: ReportResponseTimes;
    reportsPerCounselor: Array<ReportsPerCounselor>;
    reportsPerStatus: { [key: string]: number; };
    reportsPerIntegrityReportType: { [key: string]: number; };
    reportsPerIntegrityViolationType: { [key: string]: number; };
    reportsPerResolution: { [key: string]: number; };
}

