import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import {RadioComponent} from './radio/radio.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-radiogroup',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RadiogroupComponent implements OnInit, OnDestroy, AfterViewInit {

  @ContentChildren(RadioComponent) radios?: QueryList<RadioComponent>;

  @Input() selected: any | any[];
  @Input() allowDeselect: boolean = true;
  @Output() onSelected = new EventEmitter<any | any[]>();
  @Input() margin?: 'small' | 'large' = 'large';
  @Input() multiSelect: boolean = false;

  radioSubscriptions: Subscription[] = [];

  constructor() {
  }

  ngOnInit() {
    if (this.multiSelect && !Array.isArray(this.selected)) {
      this.selected = [];
    }
  }

  ngAfterViewInit(): void {
    this.registerRadios();
    this.radios!.changes.subscribe(_ => {
      this.clearRadioListeners();
      this.registerRadios();
    });
  }

  ngOnDestroy() {
    this.clearRadioListeners();
  }

  private clearRadioListeners() {
    this.radioSubscriptions.forEach(sub => sub.unsubscribe());
  }

  registerRadios() {

    this.radios!.forEach(radio => {

      // We need to do this in a timeout, otherwise we get a "value was changed after it was checked" error
      if (this.multiSelect && !radio.showAsSquare) {
        setTimeout(() => radio.showAsSquare = this.multiSelect, 0);
      }

      this.radioSubscriptions.push(radio.onSelected.subscribe(selected => {

        console.log('Radio ' + radio.value + ' selected: ' + selected);

        if (!selected && !this.allowDeselect && !this.multiSelect) {
          return;
        }

        // Update the state of all radios; in case of single-select, it could be that we need to turn of other radio buttons because this one was selected
        for (let r of this.radios!.toArray()) {
          if (r.value === radio.value) {
            r.selected = selected;
          } else {
            if (!this.multiSelect) {
              r.selected = false;
            }
          }
        }

        if (this.multiSelect) {
          let selectedValues: any[] = [];
          for (let r of this.radios!.toArray()) {
            if (r.selected) {
              selectedValues.push(r.value);
            }
          }
          this.selected = selectedValues;
        } else {
          this.selected = selected ? radio.value : undefined;
        }
        this.onSelected.emit(this.selected);
      }));
    })
  }

}
