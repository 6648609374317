/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportContactMomentView } from './reportContactMomentView';
import { ReportJournalEntryMediaView } from './reportJournalEntryMediaView';


export interface ReportJournalEntryView { 
    createdAt: string;
    updatedAt: string;
    uid: string;
    reportUid: string;
    notes?: string;
    nextSteps?: string;
    contactMoment?: ReportContactMomentView;
    media?: Array<ReportJournalEntryMediaView>;
    isEditable: boolean;
}

