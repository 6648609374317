import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { isWeb } from '../../util/IsWeb';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  // We need this so we can apply "width: 100%" to any buttons that are passed via ng-content
  encapsulation: ViewEncapsulation.None,
})
export class CtaComponent implements OnInit {
  @Input() safeArea: boolean = true;
  protected readonly isWeb = isWeb;

  constructor() {}

  ngOnInit() {}
}
