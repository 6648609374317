import {BehaviorSubject, filter, map, Observable, Subscription, takeWhile} from 'rxjs';
import {Pageable} from './modelv2/Pageable';


enum LoadState {
  UNINITIALIZED = 0,
  LOADING = 1,
  NEEDS_REFRESH = 2,
  READY = 3,
}


export class PaginatedDataStore<T> {

  private readonly data = new BehaviorSubject<T[]>([]);
  private loadState = new BehaviorSubject<LoadState>(LoadState.UNINITIALIZED);

  private lastTimeLoaded: number = 0;

  private currentRequest?: Observable<Pageable<T>>;
  private currentRequestSubscription?: Subscription;


  /**
   * <pre>
   * new PaginatedDataStore(page => this.api.getMyDataType(page))
   * </pre>
   * @param dataRetriever
   * @param maxCacheAgeMs
   */
  constructor(private readonly dataRetriever: (page?: number) => Observable<Pageable<T>>,
              private readonly maxCacheAgeMs: number = 5 * 60 * 1000 - 10000) {
  }

  /**
   * Get a subscription to the data, which will be updated when new data is added, or more data is loaded.
   * This also means that as soon as a page is available, data will be emitted. But the observable will never complete.
   */
  stream(): Observable<T[]> {
    this.loadDataIfNoCache();
    return this.loadState
      .pipe(
        filter(state => state !== LoadState.UNINITIALIZED),
        map(() => this.data.value)
      );
  }

  /**
   * Returns the data as soon as all data is loaded and completes the observable
   * this.getData().subscribe(next: () => {}, complete: () => {} )
   */
  getData(emitPartial: boolean = false): Observable<T[]> {
    this.loadDataIfNoCache();
    return this.loadState
      .pipe(
        filter(loadState => (emitPartial && loadState === LoadState.LOADING) || loadState === LoadState.READY),
        takeWhile(loadState => loadState !== LoadState.READY, true),
        map(() => this.data.value),
      );
  }

  clearCache() {
    this.lastTimeLoaded = 0;
    this.loadState.next(LoadState.NEEDS_REFRESH);

    if (this.currentRequest != undefined) {
      console.log("Clearing cache while an active request was running; Cancelling current request");
      this.currentRequest = undefined;
      this.currentRequestSubscription?.unsubscribe();
    }
  }

  /**
   * Useful if a new item was created, to signify the "store" that it can be added without calling the backend
   * @param item
   */
  addItem(item: T) {
    this.data.next([item, ...this.data.value]);
    // We emit the same load state value: if we were ready, we're still ready, if we were loading, we're still loading
    this.loadState.next(this.loadState.value);
  }

  private loadDataIfNoCache() {
    const cacheExpired = this.loadState.value === LoadState.NEEDS_REFRESH || this.lastTimeLoaded + this.maxCacheAgeMs < Date.now();
    if (this.loadState.value == LoadState.UNINITIALIZED || cacheExpired) {
      // if (this.initialized && cacheExpired) console.log("Cache expired!");
      this.loadState.next(LoadState.LOADING);
      this.lastTimeLoaded = Date.now();
      this.loadData(undefined, cacheExpired);
    }
  }

  private loadData(page?: number | undefined, clearData: boolean = false) {

    if (this.currentRequest != undefined) {
      this.currentRequest = undefined;
      this.currentRequestSubscription?.unsubscribe();
    }
    const currentRequest = this.dataRetriever(page);
    this.currentRequest = currentRequest;
    this.currentRequestSubscription = this.currentRequest.subscribe(res => {

      if (currentRequest !== this.currentRequest) {
        // Note that this shouldn't happen, since this subscription should have been "unsubscribed" instead of getting here
        console.warn("This request was already replaced by a new one, so we will ignore the result");
        return;
      }

      this.currentRequestSubscription?.unsubscribe();
      this.currentRequest = undefined;

      if (clearData) {
        this.data.next([...res.pages]);
      } else {
        this.data.next([...this.data.value, ...res.pages]);
      }
      const fullyLoaded = res.currentPage + 1 >= res.totalPages;
      this.loadState.next(fullyLoaded ? LoadState.READY : LoadState.LOADING);
      if (!fullyLoaded) {
        // Load the next page as well
        this.loadData(res.currentPage + 1);
      }
    });
  }
}
