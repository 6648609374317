import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { EventType, Router } from '@angular/router';
import { AnalyticsService } from './service/analytics.service';
import { PushNotificationService } from './core/services/push-notification.service';
import { LocaleService } from './service/locale.service';
import { NavRestoreService } from './core/services/nav-restore.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild(IonRouterOutlet) routerOutlet!: IonRouterOutlet;
  private isInReport: boolean = false;

  // noinspection JSUnusedLocalSymbols
  constructor(private translate: TranslateService,
              private auth: AuthService,
              private platform: Platform,
              private router: Router,
              private analytics: AnalyticsService,
              private push: PushNotificationService,
              private locale: LocaleService,
              private navRestoreService: NavRestoreService,
              private zone: NgZone
  ) {
    this.navRestoreService.initialize();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            const slug = event.url.split(".com").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
        });
    });
  }

  ngOnInit(): void {
    this.platform.backButton.subscribeWithPriority(-100, () => {
      // If no one is handling the back button, I guess we should close the app
      if (!this.routerOutlet.canGoBack()) {
        App.minimizeApp().then();
      }
    });

    this.router.events.subscribe(event => {
      if (event.type === EventType.NavigationEnd) {
        this.isInReport = event.url.startsWith('/report');
      }
    });

    window.onclick = () => this.resetPinIfInReport();
    window.onkeydown = () => this.resetPinIfInReport();
    window.onpointermove = () => this.resetPinIfInReport();

  }

  private resetPinIfInReport() {
    if (this.isInReport) {
      // If the user is doing stuff inside the "report" part of the app, we want to reset the pincode timer
      this.auth.resetPincodeTimer();
    }
  }
}

