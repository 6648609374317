/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserContactInfoView { 
    phoneNumber: string;
    personalEmail: string;
    preferredContactMethod: UserContactInfoView.PreferredContactMethodEnum;
}
export namespace UserContactInfoView {
    export type PreferredContactMethodEnum = 'PHONE' | 'EMAIL';
    export const PreferredContactMethodEnum = {
        Phone: 'PHONE' as PreferredContactMethodEnum,
        Email: 'EMAIL' as PreferredContactMethodEnum
    };
}


