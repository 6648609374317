import { AfterViewInit, Component, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { hCaptchaLoader } from '@hcaptcha/loader';
import { environment } from 'src/environments/environment';
import UidGenerator from '../../util/UidGenerator';

@Component({
  selector: 'app-hcaptcha',
  templateUrl: './hcaptcha.component.html',
})
export class HcaptchaComponent implements AfterViewInit, OnDestroy {
  @Output() captchaResponse: EventEmitter<string> = new EventEmitter<string>();
  protected loading = true;

  protected containerId: string = UidGenerator.generate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- hCaptcha types don't seem to work
  private hCaptcha?: any;
  private widgetId?: string;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.runCaptcha().then();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Adding a global function
    (window as any).onCaptchaSuccess = this.onCaptchaSuccess.bind(this);
  }

  ngOnDestroy() {
    if (this.hCaptcha && this.widgetId) {
      this.hCaptcha.reset(this.widgetId);
    }
  }

  async runCaptcha() {
    this.hCaptcha = await hCaptchaLoader();

    this.widgetId = this.hCaptcha.render(this.containerId, {
      sitekey: environment.hCaptchaSiteKey,
      callback: 'onCaptchaSuccess',
    });

    this.loading = false;
  }

  onCaptchaSuccess(token: string) {
    console.log('Captcha completed', token);
    this.ngZone.run(() => {
      this.captchaResponse.emit(token);
    });
  }
}
