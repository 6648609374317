import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styles: [".terms-text { text-align: justify}"],
})
export class TermsComponent {

  constructor() { }

}
