/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationContentPageTranslationView } from './organizationContentPageTranslationView';
import { ReportIntegrityView } from './reportIntegrityView';


export interface PageableResponseReportIntegrityView { 
    pages: Array<ReportIntegrityView>;
    totalPages: number;
    itemCount: number;
    currentPage: number;
    contentPageTranslations?: Array<OrganizationContentPageTranslationView>;
}

