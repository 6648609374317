/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerifyCredentialsResponse { 
    valid: boolean;
    requiredAction?: VerifyCredentialsResponse.RequiredActionEnum;
}
export namespace VerifyCredentialsResponse {
    export const RequiredActionEnum = {
        ConfigurePin: 'CONFIGURE_PIN',
        ConfigureTotp: 'CONFIGURE_TOTP',
        ConfirmTotp: 'CONFIRM_TOTP'
    } as const;
    export type RequiredActionEnum = typeof RequiredActionEnum[keyof typeof RequiredActionEnum];
}


