/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaView } from './mediaView';


export interface ContentPageTranslationBlockView { 
    uid: string;
    sortOrder: number;
    type: ContentPageTranslationBlockView.TypeEnum;
    body?: string;
    media?: MediaView;
    embedUrl?: string;
}
export namespace ContentPageTranslationBlockView {
    export const TypeEnum = {
        Text: 'TEXT',
        Image: 'IMAGE',
        Audio: 'AUDIO',
        File: 'FILE',
        Embed: 'EMBED'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


