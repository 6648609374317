<div style="aspect-ratio: {{width}}/{{height}}">
  <img
    *ngIf="src"
    [cache-img]="src"
    [class]="classList"
    loading="lazy"
    class="w-100 h-100 object-fit-contain max-h-[60vh]"
  />

  <ion-skeleton-text *ngIf="!src" style="width: 100%; height: 100%;"/>
</div>
