/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewUndesirableReportResolution { 
    resolution: NewUndesirableReportResolution.ResolutionEnum;
    resolutionComment?: string;
    reporterMoved: NewUndesirableReportResolution.ReporterMovedEnum;
    accusedMoved: NewUndesirableReportResolution.AccusedMovedEnum;
    stepsTaken: Array<NewUndesirableReportResolution.StepsTakenEnum>;
    stepsTakenOtherComment?: string;
    remarks?: string;
    aftercare: boolean;
}
export namespace NewUndesirableReportResolution {
    export type ResolutionEnum = 'ESCALATED' | 'DEESCALATED';
    export const ResolutionEnum = {
        Escalated: 'ESCALATED' as ResolutionEnum,
        Deescalated: 'DEESCALATED' as ResolutionEnum
    };
    export type ReporterMovedEnum = 'LEFT_ORGANIZATION' | 'MOVED_WITHIN_ORGANIZATION' | 'NOT_MOVED';
    export const ReporterMovedEnum = {
        LeftOrganization: 'LEFT_ORGANIZATION' as ReporterMovedEnum,
        MovedWithinOrganization: 'MOVED_WITHIN_ORGANIZATION' as ReporterMovedEnum,
        NotMoved: 'NOT_MOVED' as ReporterMovedEnum
    };
    export type AccusedMovedEnum = 'LEFT_ORGANIZATION' | 'MOVED_WITHIN_ORGANIZATION' | 'NOT_MOVED';
    export const AccusedMovedEnum = {
        LeftOrganization: 'LEFT_ORGANIZATION' as AccusedMovedEnum,
        MovedWithinOrganization: 'MOVED_WITHIN_ORGANIZATION' as AccusedMovedEnum,
        NotMoved: 'NOT_MOVED' as AccusedMovedEnum
    };
    export type StepsTakenEnum = 'MEETING_WITH_VP' | 'RESOLVED_AFTER_TALKING_TO_INVOLVED' | 'REPORT_RETRACTED' | 'MEDIATION' | 'TALKED_TO_MANAGEMENT' | 'TALKED_TO_HR' | 'OFFICIAL_COMPLAINT' | 'OTHER';
    export const StepsTakenEnum = {
        MeetingWithVp: 'MEETING_WITH_VP' as StepsTakenEnum,
        ResolvedAfterTalkingToInvolved: 'RESOLVED_AFTER_TALKING_TO_INVOLVED' as StepsTakenEnum,
        ReportRetracted: 'REPORT_RETRACTED' as StepsTakenEnum,
        Mediation: 'MEDIATION' as StepsTakenEnum,
        TalkedToManagement: 'TALKED_TO_MANAGEMENT' as StepsTakenEnum,
        TalkedToHr: 'TALKED_TO_HR' as StepsTakenEnum,
        OfficialComplaint: 'OFFICIAL_COMPLAINT' as StepsTakenEnum,
        Other: 'OTHER' as StepsTakenEnum
    };
}


