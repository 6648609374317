import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  lastValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  share,
  tap,
  throwError,
} from 'rxjs';
import { decodeJwt } from 'jose';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginResponse } from '../generated-api';
import { NO_AUTH } from './auth.interceptor';
import { isWeb } from '../util/IsWeb';

@Injectable({
  providedIn: 'root',
})
export class TokenManagerService {
  private activationToken: string | null = null;

  private accessToken = new BehaviorSubject<string | undefined>(undefined);
  private refreshToken = new BehaviorSubject<string | undefined>(undefined);
  onSuccessfulRefreshTokenLogin = new ReplaySubject<LoginResponse>(1);

  private accessTokenValidUntil?: number;
  private refreshTokenValidUntil?: number;
  private currentLoadRequest?: Observable<LoginResponse | undefined>;

  constructor(private http: HttpClient) {}

  setAccessToken(token: string) {
    const decodedToken = decodeJwt(token);
    this.accessTokenValidUntil = decodedToken.exp
      ? decodedToken.exp * 1000
      : undefined;
    this.accessToken.next(token);
  }

  setRefreshToken(token: string) {
    const decodedToken = decodeJwt(token);
    this.refreshTokenValidUntil = decodedToken.exp
      ? decodedToken.exp * 1000
      : undefined;
    this.refreshToken.next(token);
  }

  clearAccessToken() {
    this.accessToken.next(undefined);
    this.accessTokenValidUntil = undefined;
  }

  clearRefreshToken() {
    this.refreshToken.next(undefined);
    this.refreshTokenValidUntil = undefined;
  }

  async getAccessToken(): Promise<string | undefined> {
    if (
      this.accessToken.value !== undefined &&
      (this.accessTokenValidUntil === undefined ||
        this.accessTokenValidUntil > Date.now() + 10 * 1000)
    ) {
      return this.accessToken.value;
    }

    if (
      !isWeb() &&
      (!this.refreshToken.value ||
        (this.refreshTokenValidUntil !== undefined &&
          this.refreshTokenValidUntil < Date.now()))
    ) {
      return undefined;
    }

    if (this.currentLoadRequest === undefined) {
      this.currentLoadRequest = this.loadNewToken().pipe(
        finalize(() => {
          this.currentLoadRequest = undefined;
        }),
        share()
      );
    }
    return await lastValueFrom(
      this.currentLoadRequest.pipe(
        catchError(() => of(undefined)),
        map((t) => t?.accessToken)
      )
    );
  }

  stream(): Observable<string | undefined> {
    return this.accessToken.asObservable();
  }

  setActivationToken(token: string) {
    this.activationToken = token;
  }

  getActivationToken(): string | null {
    return this.activationToken;
  }

  clearActivationToken() {
    this.activationToken = null;
  }

  private loadNewToken(): Observable<LoginResponse | undefined> {
    const refreshToken = this.refreshToken.value;

    // If we're on the web, it could be that a refresh token is available as a cookie. We try that.
    if (!isWeb() && !refreshToken) {
      this.clearAccessToken();
      this.clearRefreshToken();
      return of(undefined);
    }

    // Note that this is the main/only login request when coming into the app using the SAML flow: when using the SAML flow, a refresh token is set as a cookie, so the app can utilize that to get a new access token.
    return this.http
      .post<Required<LoginResponse>>(
        environment.apiV2Url +
          '/unauthenticated/refresh/' +
          (isWeb() ? 'web-app' : 'app'),
        {
          refreshToken,
        },
        {
          withCredentials: true,
          context: new HttpContext().set(NO_AUTH, true),
        }
      )
      .pipe(
        catchError((e) => {
          console.error('Error loading new token', e);
          this.clearAccessToken();
          this.clearRefreshToken();
          return throwError(() => e);
        }),
        tap((response: LoginResponse) => {
          this.handleLoginResponse(response);
          this.onSuccessfulRefreshTokenLogin.next(response);
          return response;
        })
      );
  }

  handleLoginResponse(response: LoginResponse) {
    this.setAccessToken(response.accessToken);
    if (response.refreshToken) {
      this.setRefreshToken(response.refreshToken);
    }
  }
}
