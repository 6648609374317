import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from './user.service';
import {combineLatest, lastValueFrom, Subscription} from 'rxjs';
import {UserAppSessionView} from '../generated-api';
import {CommunicatorV2Service} from '../api/communicatorV2.service';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements OnDestroy {

  private userAndOrganizationSubscription?: Subscription;

  private currentAppSession?: UserAppSessionView;
  private heartbeatInterval?: any //because Timeout type is weird;

  constructor(private userService: UserService, private comm: CommunicatorV2Service) {
    this.userAndOrganizationSubscription =
      combineLatest([this.userService.currentOrganization, this.userService.user])
        .subscribe(async ([organization, user]) => {
          if (this.currentAppSession && (!organization || !user)) {
            // The user logged out of the organization
            await this.endAppSession();
            return;
          }
          if (!organization || !user) {
            return;
          }
          if (this.currentAppSession && this.currentAppSession.organizationUid !== organization.uid) {
            // The user switched organizations
            await this.endAppSession();
          }

          await this.startAppSession(organization.uid).then()
        });
    App.addListener('appStateChange', ({isActive}) => {
      if (!isActive) {
        this.endAppSession().then()
      } else {
        // If we got "restored" from the background, we start a new session
        if (this.userService.currentOrganization.value && this.userService.user.value) {
          this.startAppSession(this.userService.currentOrganization.value.uid).then()
        }
      }
    });
  }

  private async appSessionHeartbeat() {
    if (!this.currentAppSession) return;

    await lastValueFrom(this.comm.heartbeatAppSession(this.currentAppSession.uid))

  }

  private async endAppSession() {
    this.clearHeartbeat();
    if (!this.currentAppSession) return;
    await lastValueFrom(this.comm.endAppSession(this.currentAppSession.uid));
  }

  private async startAppSession(organizationUid: string) {
    let version;
    try {
      const info = await App.getInfo();
      version = 'v' + info.version + ' (' + info.build + ')';
    } catch (e) {
      // Probably, we're web
      console.warn('Unable to get app info', e);
      version = 'v? (web)';
    }
    console.log(`Starting app session for analytics, {organizationUid: '${organizationUid}', appVersion: '${version}', platform: '${Capacitor.getPlatform()}'}`);
    this.currentAppSession = await lastValueFrom(this.comm.startAppSession({
      organizationUid,
      appVersion: version,
      platform: Capacitor.getPlatform(),
      heartbeatSeconds: environment.analyticsSessionHeartbeatSeconds,
    }));

    this.heartbeatInterval = setInterval(() => this.appSessionHeartbeat(), Math.max(environment.analyticsSessionHeartbeatSeconds - 10, 10) * 1000);
  }

  clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
  }

  ngOnDestroy(): void {
    this.userAndOrganizationSubscription?.unsubscribe();
    this.clearHeartbeat();
  }

  async notifyPageView(contentPageUid: string): Promise<string | undefined> {
    console.log(`Notifying analytics about page view {contentPageUid: '${contentPageUid}'`)
    if (!this.currentAppSession) {
      console.warn('Unable to notify analytics about page view, no active app session');
      return undefined;
    }
    const activity = await lastValueFrom(this.comm.createContentPageActivity({
      contentPageUid,
      organizationUid: this.currentAppSession?.organizationUid!
    }));
    return activity.uid;
  }

  async notifyPageClose(activityUid: string): Promise<void> {
    console.log(`Notifying analytics about page close {activityUid: '${activityUid}'}`)
    await lastValueFrom(this.comm.closeContentPageActivity(activityUid));
  }
}


