import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {firstValueFrom} from 'rxjs';
import {CommunicatorV2Service} from '../../../api/communicatorV2.service';

@Component({
  selector: 'app-media-audio',
  templateUrl: './media-audio.component.html',
  styleUrls: ['./media-audio.component.scss']
})
export class MediaAudioComponent implements OnInit {
  @Input() uid: string = ""

  src?: string

  constructor(private communicator: CommunicatorV2Service) {
  }

  getToken(mediaUid: string): Promise<string> {
    return firstValueFrom(this.communicator.getMediaToken(mediaUid));
  }

  async ngOnInit() {
    const token = await this.getToken(this.uid);
    this.src = `${environment.apiV2Url}/media/${this.uid}/download?token=${token}`;
  }
}
