/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserContactInfoView } from './userContactInfoView';
import { OrganizationViewMinimal } from './organizationViewMinimal';
import { UserView } from './userView';


export interface SosView { 
    uid: string;
    createdAt: string;
    user: UserView;
    organization: OrganizationViewMinimal;
    latitude: string;
    longitude: string;
    userContactInfo?: UserContactInfoView;
}

