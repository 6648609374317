/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationViewMinimal } from './organizationViewMinimal';
import { MediaView } from './mediaView';
import { QuestionnaireTranslationView } from './questionnaireTranslationView';


export interface QuestionnaireMinimalView { 
    uid: string;
    type: QuestionnaireMinimalView.TypeEnum;
    feedIcon?: MediaView;
    headerImage?: MediaView;
    translations: Array<QuestionnaireTranslationView>;
    maxPoints: number;
    questionCount: number;
    shared: boolean;
    ownerOrganization: OrganizationViewMinimal;
}
export namespace QuestionnaireMinimalView {
    export const TypeEnum = {
        Elearning: 'ELEARNING',
        Survey: 'SURVEY'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


