import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { LocalizedAlertService } from '../../../service/localized-alert.service';
import { NewSos, OrganizationView } from '../../../generated-api';
import { CommunicatorV2Service } from '../../../api/communicatorV2.service';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-sos',
  templateUrl: './sos.component.html',
  styleUrls: ['./sos.component.scss'],
})
export class SosComponent implements OnInit, OnDestroy {
  countdown: number = 10;
  @Output() cancelSos: EventEmitter<boolean> = new EventEmitter<boolean>();
  cancelled: boolean = false;
  timeoutRef: any;
  counterRunning: boolean = false;
  userLocation: string = '';
  showNoLocation: boolean = false;
  organization?: OrganizationView;

  constructor(
    private communicator: CommunicatorV2Service,
    private alert: LocalizedAlertService,
    private userService: UserService
  ) {
    this.userService.currentOrganization.subscribe((organization) => {
      if (organization) {
        this.organization = organization;
      }
    });
  }

  async ngOnInit() {
    this.cancelled = false;
    try {
      await this.retrieveLocation();
    } catch (e) {
      console.warn(e);
      this.showNoLocation = true;
    }
    // It could be that the user cancelled the SOS while we were retrieving the location
    if (!this.cancelled) {
      this.startCountdown();
    } else {
      console.log('User cancelled');
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutRef);
  }

  async retrieveLocation(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // Note that the SOS component is an overlay which is triggered using an ngIf (and will then reinitialize)
      const timeoutId = setTimeout(() => {
        reject('No location found');
      }, 5000);

      try {
        const coordinates = await Geolocation.getCurrentPosition();
        this.userLocation = `${coordinates.coords.latitude},${coordinates.coords.longitude}`;
        console.log('Current position:', coordinates);
        this.showNoLocation = !this.userLocation;
        if (this.userLocation) {
          resolve(this.userLocation);
        } else {
          reject('No location found');
        }
      } catch (error) {
        console.warn('Error getting location', error);
        reject('No location found');
      } finally {
        clearTimeout(timeoutId);
      }
    });
  }

  startCountdown() {
    if (this.countdown === 0) {
      if (!this.userLocation) {
        this.userLocation = '0,0';
      }

      const latitude = this.userLocation.split(',')[0];
      const longitude = this.userLocation.split(',')[1];

      const newSos: NewSos = {
        organizationUid: this.organization?.uid ?? '',
        latitude: latitude,
        longitude: longitude,
      };
      this.communicator.sendSOS(newSos).subscribe({
        next: (_) => {
          this.alert.showTopMessage('SOS', 'SOS');
        },
      });
    }
    if (this.countdown > 0) {
      this.counterRunning = true;
      this.timeoutRef = setTimeout(() => {
        this.countdown--;
        this.startCountdown();
      }, 1000);
    } else {
      this.cancel();
    }
  }

  cancel() {
    clearTimeout(this.timeoutRef);
    this.counterRunning = false;
    this.cancelled = true;
    this.cancelSos.emit(true);
  }
}
