// slice-string.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceString'
})
export class SliceStringPipe implements PipeTransform {

  transform(value: string | undefined, maxLength: number): string {
    if (!value) return '';

    if (value.length > maxLength) {
      return `${value.slice(0, maxLength)}...`;
    }
    return value;
  }
}
