import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() audioUrl: string | null = null;
  sliderValue: number = 0;
  timeInSeconds: number = 0
  sliderDisabled: boolean = true;
  startTimeInSeconds: number = 0
  @ViewChild('audioPlayer', {static: true}) audioPlayerRef!: ElementRef;
  isPlaying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  progress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentTimeInSeconds: number = 0;
  isDragging: boolean = false;
  @Output() emitAudio: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const audioEl = this.audioPlayerRef.nativeElement;
    audioEl.addEventListener('timeupdate', () => {
      if (!this.isDragging) {
        if (audioEl.duration === 0 || Number.isNaN(audioEl.duration)) {
          this.sliderValue = 0;
        } else {
          this.sliderValue = (audioEl.currentTime / audioEl.duration) * 100;
        }
        this.startTimeInSeconds = Math.floor(audioEl.currentTime);
        console.log('timeupdate event - sliderValue:', this.sliderValue, ' currentTime:', audioEl.currentTime);
      }
    });
    audioEl.addEventListener('loadedmetadata', () => {
      this.timeInSeconds = audioEl.duration;
      this.sliderDisabled = false;
      console.log('Audio metadata loaded - duration:', audioEl.duration);
    });
    audioEl.addEventListener('error', () => {
      console.error('Error loading audio');
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioUrl'] && changes['audioUrl'].currentValue) {
      const audioEl = this.audioPlayerRef.nativeElement;
      audioEl.src = changes['audioUrl'].currentValue;
      audioEl.load();
      this.currentTimeInSeconds = 0;
    }
  }

  playAudio() {
    const audioEl = this.audioPlayerRef.nativeElement;
    audioEl.play();
    this.isPlaying.next(true);
  }

  onSliderStart() {
    this.isDragging = true;
  }

  onSliderChange(event: any) {
    const audioEl = this.audioPlayerRef.nativeElement;
    this.sliderValue = event.target.value;
    console.log('Slider changed - sliderValue:', this.sliderValue, ' isDragging:', this.isDragging);
    if (!this.isDragging) {
      const newTime = (this.sliderValue / 100) * audioEl.duration;
      console.log('Not dragging - Intending to set currentTime to newTime:', newTime);
      console.log('Audio Network State:', audioEl.networkState);
      if (audioEl.readyState >= 4) {
        setTimeout(() => {
          audioEl.currentTime = newTime;  // Set as number
          console.log('CurrentTime set to:', audioEl.currentTime, ' after delay');
          this.isPlaying.next(!audioEl.paused);
        }, 100);
      }
    }
  }


  onSliderEnd() {
    this.isDragging = false;
    const audioEl = this.audioPlayerRef.nativeElement;
    const newTime = (this.sliderValue / 100) * audioEl.duration;
    console.log('Slider released - setting currentTime to newTime:', newTime);
    if (audioEl.readyState >= 4) {
      audioEl.currentTime = newTime;  // Set as number
      console.log('CurrentTime after release:', audioEl.currentTime);
      this.isPlaying.next(!audioEl.paused);
    }
  }

  pauseAudio() {
    const audioEl = this.audioPlayerRef.nativeElement;
    audioEl.pause();
    this.isPlaying.next(false);
  }
}
