<div [ngStyle]="!cover && width && height ? {'aspect-ratio': width/height } : {'width': '100%', 'height': '100%'}">
  <img
    *ngIf="src"
    [cache-img]="src"
    [class]="classList"
    loading="lazy"
    [ngClass]="{'object-fit-cover': cover, 'object-fit-contain': !cover}"
    class="w-100 h-100 max-h-[60vh]"
  />

  <ion-skeleton-text *ngIf="!src" style="width: 100%; height: 100%;"/>
</div>
