/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageTranslationBlockNew { 
    sortOrder: number;
    type: ContentPageTranslationBlockNew.TypeEnum;
    body?: string;
    media?: string;
    embedUrl?: string;
}
export namespace ContentPageTranslationBlockNew {
    export const TypeEnum = {
        Text: 'TEXT',
        Image: 'IMAGE',
        Audio: 'AUDIO',
        File: 'FILE',
        Embed: 'EMBED'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


