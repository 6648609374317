import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';

let _isWeb: boolean;

export const isWeb = () => {
  if (_isWeb !== undefined) return _isWeb;

  if (Capacitor.isNativePlatform()) {
    _isWeb = false;
    return _isWeb;
  }

  if (!environment.production) {
    // For debug purposes, we want the local running version to be considered a web version, but in production we want to check the user agent
    _isWeb =
      !navigator.userAgent.includes('Android') &&
      !navigator.userAgent.includes('iPhone') &&
      !navigator.userAgent.includes('iPad') &&
      !navigator.userAgent.includes('iPod');
  }

  return _isWeb;
};
