/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportInvolvedPartyView } from './reportInvolvedPartyView';


export interface IntegrityNoteNew { 
    integrityViolatedAt?: string;
    location: string;
    reporterParty?: ReportInvolvedPartyView;
    accusedParty?: ReportInvolvedPartyView;
    integrityCategoryCode?: string;
    integrityCategoryRemarks: string;
    remarks: string;
    severity: IntegrityNoteNew.SeverityEnum;
    severityRemarks: string;
}
export namespace IntegrityNoteNew {
    export const SeverityEnum = {
        Low: 'LOW',
        Medium: 'MEDIUM',
        High: 'HIGH'
    } as const;
    export type SeverityEnum = typeof SeverityEnum[keyof typeof SeverityEnum];
}


