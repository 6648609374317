import { AuthState } from './auth.service';
import { CanActivateFn } from '@angular/router';
import { authStateGuard } from './auth-state.guard';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { NavRestoreService } from '../core/services/nav-restore.service';
import { isWeb } from '../util/IsWeb';

export const loggedInGuard: CanActivateFn = (_route, _state) => {
  const navRestore = inject(NavRestoreService);
  return authStateGuard(AuthState.LOGGED_IN, AuthState.LOGGED_IN_WITH_PIN)
    .pipe(
      map(res => {
        if (res) {
          // The auth state guard is happy (so state wise we're fine), but if there was a nav state to restore (and we are on web), we should do that now
          if (isWeb()) {
            return navRestore.restoreAsUrlTree() ?? res;
          }
        }
        return res;
      })
    );
}
