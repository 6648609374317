/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateUserMe { 
    name?: string;
    email?: string;
    phoneNumber?: string;
    personalEmail?: string;
    preferredContactMethod?: UpdateUserMe.PreferredContactMethodEnum;
    onLeaveStart?: string;
    onLeaveEnd?: string;
    locale?: string;
}
export namespace UpdateUserMe {
    export const PreferredContactMethodEnum = {
        Phone: 'PHONE',
        Email: 'EMAIL'
    } as const;
    export type PreferredContactMethodEnum = typeof PreferredContactMethodEnum[keyof typeof PreferredContactMethodEnum];
}


