<div class="header-audio">
  <audio #audioPlayer [src]="audioUrl" class="d-none" preload="metadata"></audio>

  <button (click)="playAudio()" *ngIf="(isPlaying | async) === false">
    <ion-icon class="play" name="play"></ion-icon>
  </button>

  <button (click)="pauseAudio()" *ngIf="(isPlaying | async) === true">
    <ion-icon class="pause red" name="pause-circle"></ion-icon>
  </button>

  <span>{{ startTimeInSeconds  | duration }}</span>

  <input
    (input)="onSliderChange($event)"
    (mousedown)="onSliderStart()"
    (mouseup)="onSliderEnd()"
    (touchend)="onSliderEnd()"
    (touchstart)="onSliderStart()"
    *ngIf="audioUrl"
    [(ngModel)]="sliderValue"
    [disabled]="sliderDisabled"
    [ngModelOptions]="{standalone: true}"
    max="100"
    min="0"
    name="logbook audio"
    step="0.1"
    type="range"
    class="w-100"
  >

  <div class="audio-length">
    <span>{{ timeInSeconds  | duration }}</span>
  </div>
</div>
