/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewReportIntegrity } from './newReportIntegrity';
import { NewReportUndesirable } from './newReportUndesirable';


export interface NewReport { 
    reportType: NewReport.ReportTypeEnum;
    organizationUid: string;
    counselorUid?: string;
    userUid?: string;
    canWeContact?: boolean;
    contactName?: string;
    contactFunction?: string;
    contactType?: NewReport.ContactTypeEnum;
    contactEmail?: string;
    contactPhone?: string;
    initialContactOn?: string;
    description?: string;
    logbookUids?: Array<string>;
    reportUndesirable?: NewReportUndesirable;
    reportIntegrity?: NewReportIntegrity;
}
export namespace NewReport {
    export const ReportTypeEnum = {
        Undesirable: 'UNDESIRABLE',
        Integrity: 'INTEGRITY'
    } as const;
    export type ReportTypeEnum = typeof ReportTypeEnum[keyof typeof ReportTypeEnum];
    export const ContactTypeEnum = {
        Email: 'EMAIL',
        Phone: 'PHONE'
    } as const;
    export type ContactTypeEnum = typeof ContactTypeEnum[keyof typeof ContactTypeEnum];
}


