import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommunicatorV2Service, } from '../api/communicatorV2.service';
import { combineLatest, lastValueFrom, Subscription } from 'rxjs';
import { LocalizedAlertService } from '../service/localized-alert.service';
import { NavController } from '@ionic/angular';
import { FilePicker, PickFilesOptions, } from '@capawesome/capacitor-file-picker';
import { KeyValue } from '@angular/common';
import { Feedback, FeedbackAttachment, FeedbackSubject } from '../api/modelv2/Feedback';
import { UserService } from '../service/user.service';
import { isWeb } from '../util/IsWeb';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  protected sending = false;
  protected valid = false;
  protected acceptedTerms = false;
  protected isWeb = isWeb();

  protected currentFeedback: Feedback = {
    subject: FeedbackSubject.SUGGESTION_CONTENT,
    name: '',
    email: '',
    organization: null,
    description: '',
    attachments: [],
  };
  protected readonly FeedbackSubject = FeedbackSubject;
  private userOrgSub?: Subscription;

  constructor(
    private communicator: CommunicatorV2Service,
    private alert: LocalizedAlertService,
    private nav: NavController,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userOrgSub = combineLatest([this.userService.user, this.userService.currentOrganization])
      .subscribe(
        ([user, organization]) => {
          this.currentFeedback.name = user?.name || '';
          this.currentFeedback.email = user?.email || '';
          this.currentFeedback.organization = organization?.name || null;
        }
      );
  }

  ngOnDestroy() {
    this.userOrgSub?.unsubscribe();
  }

  async send() {
    try {
      this.sending = true;
      if (!this.isValid(this.currentFeedback)[0]) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(this.isValid(this.currentFeedback)[1]);
      }

      if (this.isWeb) {
        await lastValueFrom(this.communicator.sendFeedbackWeb(this.currentFeedback));
      } else {
        await lastValueFrom(this.communicator.sendFeedback(this.currentFeedback));
      }

      this.alert.showAlert('FeedbackSuccess', () => {
        this.goBack();
      });

      await this.reset();
    } catch (e) {
      this.alert.showAlert('FeedbackError');
      throw e;
    } finally {
      this.sending = false;
    }
  }

  reset() {
    this.currentFeedback = {
      subject: FeedbackSubject.SUGGESTION_CONTENT,
      name: '',
      email: '',
      organization: null,
      description: '',
      attachments: [],
    };
    this.acceptedTerms = false;
    return this.onChange();
  }

  goBack() {
    this.nav.back();
  }

  async selectFiles() {
    try {
      const options: PickFilesOptions = {
        readData: true,
      };

      const result = await FilePicker.pickFiles(options);
      const [file] = result.files;
      if (!file) return;

      // Convert the data we retrieved to a blob
      const blob = new Blob([Uint8Array.from(atob(file.data!), c => c.charCodeAt(0))], { type: file.mimeType });

      this.currentFeedback.attachments.push({ file: blob, name: file.name });
    } catch (error: any) {
      if (error.message === 'pickFiles canceled.') return;
      this.alert.showAlert('Default');
    }
  }

  removeFile(file: FeedbackAttachment) {
    this.currentFeedback.attachments = this.currentFeedback.attachments.filter(
      (f) => f !== file
    );
  }

  protected readonly originalOrder = (
    _a: KeyValue<string, string>,
    _b: KeyValue<string, string>
  ): number => 0;

  protected readonly onChange = () =>
    new Promise<void>((r) =>
      setTimeout(() => {
        this.valid = this.isValid(this.currentFeedback)[0];
        r();
      }, 0)
    );

  private isValid(data: Feedback): [boolean, string?] {
    if (!this.acceptedTerms) {
      return [false, 'Please accept the terms and conditions'];
    }
    if (!data.name) {
      return [false, 'Name is required'];
    }
    if (!data.email) {
      return [false, 'Email is required'];
    }
    if (
      !data.email.match(
        new RegExp(
          '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?'
        )
      )
    ) {
      return [false, 'Invalid email'];
    }
    if (!data.description) {
      return [false, 'Description is required'];
    }
    if (this.isWeb && !data.hCaptchaResponse) {
      return [false, 'Please complete the captcha'];
    }
    return [true];
  }

  onCaptchaResponse(captchaResponse: string) {
    this.currentFeedback.hCaptchaResponse = captchaResponse;
    this.onChange().then();
  }
}
