/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IntegrityResolutionView } from './integrityResolutionView';
import { UndesirableReportResolutionView } from './undesirableReportResolutionView';


export interface ReportResolutionResponse { 
    undesirable?: UndesirableReportResolutionView;
    integrity?: IntegrityResolutionView;
}

