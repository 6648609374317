/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterTokenBody { 
    token: string;
    deviceType: RegisterTokenBody.DeviceTypeEnum;
    appLoginScope?: string;
}
export namespace RegisterTokenBody {
    export const DeviceTypeEnum = {
        Ios: 'IOS',
        Android: 'ANDROID',
        WebEmail: 'WEB_EMAIL'
    } as const;
    export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];
}


