/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewMinimal } from './userViewMinimal';


export interface QuestionnaireInstanceUserViewMinimal { 
    questionnaireInstanceUid: string;
    status: QuestionnaireInstanceUserViewMinimal.StatusEnum;
    user: UserViewMinimal;
}
export namespace QuestionnaireInstanceUserViewMinimal {
    export const StatusEnum = {
        Assigned: 'ASSIGNED',
        Started: 'STARTED',
        Completed: 'COMPLETED'
    } as const;
    export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
}


