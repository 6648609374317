/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserRegistrationTokenResponse { 
    valid: boolean;
    validUntil?: string;
    target?: UserRegistrationTokenResponse.TargetEnum;
    activationToken?: string;
}
export namespace UserRegistrationTokenResponse {
    export const TargetEnum = {
        Cms: 'CMS',
        App: 'APP'
    } as const;
    export type TargetEnum = typeof TargetEnum[keyof typeof TargetEnum];
}


