<div [ngClass]="{'full-width': fullWidth, 'web': isWeb}" class="info-page-card">
  <div class="header">
    <div class="length d-flex flex-row align-items-center">
      <ion-icon [name]="randomIcon"/>

      <p class="grayed-out">
        <ion-skeleton-text [animated]="true" style="width: 6ch; height: 16px;"></ion-skeleton-text>
      </p>

      <p class="grayed-out">•</p>
      <p class="grayed-out">
        <ion-skeleton-text [animated]="true" style="width: 7ch; height: 16px"></ion-skeleton-text>
      </p>
    </div>

    <div class="image">
      <ion-skeleton-text [animated]="true" style="width: 100%; min-height: 150px" [ngStyle]="{ 'height': isWeb ? '100%' : '150px'}"></ion-skeleton-text>
    </div>
  </div>

  <div class="content">
    <div class="preview">
      <div class="title">
        <ion-skeleton-text [animated]="true" style="height: 1.2em"></ion-skeleton-text>
      </div>
      <p>
        <ion-skeleton-text [animated]="true" style="height: 1em"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="height: 1em"></ion-skeleton-text>
      </p>
    </div>
  </div>
</div>
