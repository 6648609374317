import { FilePicker, PickFilesOptions } from '@capawesome/capacitor-file-picker';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LocalizedAlertService } from '../../service/localized-alert.service';

type AllowedFileTypes = 'image/*' | 'audio/*' | 'video/*' | 'application/pdf';

@Component({
  selector: 'app-file-select-button',
  templateUrl: './file-select-button.component.html',
  styleUrls: ['./file-select-button.component.scss'],
})
export class FileSelectButtonComponent implements OnInit {
  @Output() fileSelected = new EventEmitter<{ file: Blob; name: string }>();
  @Input() allowedFileTypes: AllowedFileTypes[] = ['image/*', 'audio/*', 'video/*', 'application/pdf'];

  constructor(private alert: LocalizedAlertService) {}

  @Output()
  async selectFiles() {
    try {
      const options: PickFilesOptions = {
        readData: true,
        types: this.allowedFileTypes,
      };

      const result = await FilePicker.pickFiles(options);
      const [file] = result.files;
      if (!file) return;

      //check if the file is one of the allowed file types
      if (!this.allowedFileTypes.some((type) => file.mimeType.startsWith(type))) {
        this.alert.showAlert('InvalidAudioFileType');
        return;
      }

      // Convert the data we retrieved to a blob
      const blob = new Blob([Uint8Array.from(atob(file.data!), (c) => c.charCodeAt(0))], { type: file.mimeType });

      this.fileSelected.emit({ file: blob, name: file.name });
    } catch (error: any) {
      if (error.message === 'pickFiles canceled.') return;
      this.alert.showAlert('Default');
    }
  }

  ngOnInit() {}
}
