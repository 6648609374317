/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaView { 
    id: string;
    uid: string;
    url: string;
    name: string;
    mime: string;
    permission: MediaView.PermissionEnum;
    duration?: number;
    size: number;
    cache?: number;
    width?: number;
    height?: number;
    processing: boolean;
}
export namespace MediaView {
    export const PermissionEnum = {
        Shared: 'SHARED',
        Organization: 'ORGANIZATION',
        Logbook: 'LOGBOOK',
        ReportNote: 'REPORT_NOTE',
        ContentPage: 'CONTENT_PAGE',
        Questionnaire: 'QUESTIONNAIRE'
    } as const;
    export type PermissionEnum = typeof PermissionEnum[keyof typeof PermissionEnum];
}


