import {Observable, ReplaySubject, take, tap} from 'rxjs';

export class ResponseCache<T> {

  private readonly data: ReplaySubject<T>;
  private lastTimeLoaded: number = 0;
  private currentLoadRequest?: Observable<T>;

  constructor(private dataRetrieverFactory: () => Observable<T>, private maxAgeMs: number) {
    this.data = new ReplaySubject(1, maxAgeMs)
  }

  getData(): Observable<T> {

    if (this.currentLoadRequest === undefined && this.lastTimeLoaded + this.maxAgeMs < Date.now()) {
      this.currentLoadRequest = this.loadData();
      this.currentLoadRequest.subscribe();
    }
    return this.data
      .pipe(take(1));
  }

  /**
   * Force a reload of the cached data (response). Returns a new observable that will emit the new data.
   */
  reload(): Observable<T> {
    this.currentLoadRequest = this.loadData();
    return this.currentLoadRequest;
  }

  private loadData(): Observable<T> {
    return this.dataRetrieverFactory()
      .pipe(tap(data => {
        this.data.next(data);
        this.lastTimeLoaded = Date.now();
        this.currentLoadRequest = undefined;
      }));
  }

}
