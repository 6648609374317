<ion-content>
  <div class="page flex flex-column gap-2 mt-2 max-w-screen-md m-auto">
    <app-menu [showBackButton]="true" [showHamburger]="false" aria-label="Menu"/>

    <h1 style="margin-top: 2rem">{{ 'General.SendFeedback.Title' | translate }}</h1>

    <div class="form-item">
      <ion-label>{{ 'General.SendFeedback.SubjectQuestion' | translate }}</ion-label>
      <div class="mt-2">
        <app-radiogroup
          (onSelected)="currentFeedback.subject = $event; onChange()"
          [allowDeselect]="false"
          [margin]="'small'"
          [multiSelect]="false"
        >
          <app-radio
            *ngFor="let option of FeedbackSubject | keyvalue : originalOrder"
            [label]="('General.SendFeedback.Subject.' + option.key) | translate"
            [value]="option.value"
            size="small"
          />
        </app-radiogroup>
      </div>
    </div>

    <div class="form-item">
      <ion-label>{{ 'General.Name' | translate }}</ion-label>
      <ion-input (ngModelChange)="onChange()" [(ngModel)]="currentFeedback.name" [required]="true" autocomplete="name"/>
    </div>

    <div class="form-item">
      <ion-label>{{ 'General.Email' | translate }}</ion-label>
      <ion-input (ngModelChange)="onChange()" [(ngModel)]="currentFeedback.email" [required]="true" autocomplete="email"
                 inputmode="email"/>
    </div>

    <div class="form-item">
      <ion-label>{{ 'General.Organization' | translate }}</ion-label>
      <ion-input (ngModelChange)="onChange()" [(ngModel)]="currentFeedback.organization" autocomplete="organization"/>
    </div>

    <div class="form-item">
      <ion-label>{{ 'General.SendFeedback.YourFeedback' | translate }}</ion-label>
      <ion-textarea (ngModelChange)="onChange()" [(ngModel)]="currentFeedback.description" [required]="true"/>
    </div>

    <div class="form-item">
      <div *ngIf="currentFeedback.attachments.length" class="py-2">
        <div *ngFor="let file of currentFeedback.attachments; let i = index" aria-label="File list" class="file">
          <div class="fst-italic">
            {{ file.name }}
          </div>

          <button (click)="removeFile(file)" aria-label="Delete file" class="delete-button">
            <ion-icon class="font-1-rem" color="danger" name="trash"/>
          </button>
        </div>
      </div>
      <app-file-select-button (fileSelected)="currentFeedback.attachments.push($event)"
                              [allowedFileTypes]="['image/*', 'audio/*']"></app-file-select-button>
    </div>

    <div class="form-item">
      <app-hcaptcha (captchaResponse)="onCaptchaResponse($event)"></app-hcaptcha>
    </div>

    <div class="flex gap-2 align-items-center">
      <ion-checkbox
        (ngModelChange)="onChange()"
        [(ngModel)]="acceptedTerms"
        class="py-2"
      />
      <div>
        <span>{{ 'General.SendFeedback.AcceptTerms' | translate }}</span>&nbsp;<a
        [routerLink]="'/terms'">{{ 'General.Terms' | translate }}</a>
      </div>
    </div>

    <ion-button
      (click)="send()"
      [disabled]="sending || !valid"
      aria-label="Send feedback"
      class="fits-spinner w-100"
      type="submit"
    >
      <ion-spinner *ngIf="sending"></ion-spinner>
      {{ sending ? '' : 'General.SendFeedback.Send' | translate }}
    </ion-button>
  </div>
</ion-content>
