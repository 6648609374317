/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewUndesirableReportResolution { 
    resolution: NewUndesirableReportResolution.ResolutionEnum;
    resolutionComment?: string;
    reporterMoved: NewUndesirableReportResolution.ReporterMovedEnum;
    accusedMoved: NewUndesirableReportResolution.AccusedMovedEnum;
    stepsTaken: Array<NewUndesirableReportResolution.StepsTakenEnum>;
    stepsTakenOtherComment?: string;
    remarks?: string;
    aftercare: boolean;
}
export namespace NewUndesirableReportResolution {
    export const ResolutionEnum = {
        Escalated: 'ESCALATED',
        Deescalated: 'DEESCALATED'
    } as const;
    export type ResolutionEnum = typeof ResolutionEnum[keyof typeof ResolutionEnum];
    export const ReporterMovedEnum = {
        LeftOrganization: 'LEFT_ORGANIZATION',
        MovedWithinOrganization: 'MOVED_WITHIN_ORGANIZATION',
        NotMoved: 'NOT_MOVED'
    } as const;
    export type ReporterMovedEnum = typeof ReporterMovedEnum[keyof typeof ReporterMovedEnum];
    export const AccusedMovedEnum = {
        LeftOrganization: 'LEFT_ORGANIZATION',
        MovedWithinOrganization: 'MOVED_WITHIN_ORGANIZATION',
        NotMoved: 'NOT_MOVED'
    } as const;
    export type AccusedMovedEnum = typeof AccusedMovedEnum[keyof typeof AccusedMovedEnum];
    export const StepsTakenEnum = {
        MeetingWithVp: 'MEETING_WITH_VP',
        ResolvedAfterTalkingToInvolved: 'RESOLVED_AFTER_TALKING_TO_INVOLVED',
        ReportRetracted: 'REPORT_RETRACTED',
        Mediation: 'MEDIATION',
        TalkedToManagement: 'TALKED_TO_MANAGEMENT',
        TalkedToHr: 'TALKED_TO_HR',
        OfficialComplaint: 'OFFICIAL_COMPLAINT',
        Other: 'OTHER'
    } as const;
    export type StepsTakenEnum = typeof StepsTakenEnum[keyof typeof StepsTakenEnum];
}


