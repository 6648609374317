/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateReport { 
    status?: UpdateReport.StatusEnum;
    counselorUid?: string;
}
export namespace UpdateReport {
    export const StatusEnum = {
        Draft: 'DRAFT',
        Sent: 'SENT',
        Read: 'READ',
        Contacted: 'CONTACTED',
        Resolved: 'RESOLVED'
    } as const;
    export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
}


