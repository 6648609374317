/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PushNotificationView { 
    uid: string;
    subject: PushNotificationView.SubjectEnum;
    title: string;
    body: string;
    resourceUrl: string;
    createdAt: string;
}
export namespace PushNotificationView {
    export type SubjectEnum = 'QUESTIONNAIRE' | 'CONTENT_PAGE' | 'SOS' | 'CUSTOM';
    export const SubjectEnum = {
        Questionnaire: 'QUESTIONNAIRE' as SubjectEnum,
        ContentPage: 'CONTENT_PAGE' as SubjectEnum,
        Sos: 'SOS' as SubjectEnum,
        Custom: 'CUSTOM' as SubjectEnum
    };
}


