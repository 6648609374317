import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ImageCacheConfig, ImageCacheService} from '../services/image-cache.service';

// Inspired by: https://github.com/brhoomjs/cap-image-cache/tree/main

const EMPTY_IMG: string = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

@Directive({selector: '[cache-img]'})
export class ImageCacheDirective implements AfterViewInit, OnChanges {

  @Input('cache-img') _src?: string;
  @Input('lazy') _lazy: boolean = false;
  @Input('cache-config') _config: ImageCacheConfig = {
    includeQueryString: false,
  }
  private _alreadyRendered: boolean = false;
  private _observer?: IntersectionObserver;

  constructor(
    private readonly element: ElementRef,
    private capImageCacheService: ImageCacheService
  ) {
  }

  async ngAfterViewInit() {
    if (this._lazy) {
      this.startObserving();
    } else {
      this.loadImage().then();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['_src']) {
      this._alreadyRendered = false;
      if (!this._lazy) {
        this.loadImage().then();
      }
    }
  }

  private async loadImage() {

    if (this.element.nativeElement.nodeName === 'IMG' && !this.element.nativeElement.src) {
      this.element.nativeElement.src = EMPTY_IMG;
    }

    if (this._src) {
      const result = await this.capImageCacheService.getImageSrc(this._src, this._config);
      if (this.element.nativeElement.nodeName !== 'IMG') {
        this.element.nativeElement.style.backgroundImage = `url('${result.data}')`;
      } else {
        this.element.nativeElement.src = result.data;
      }

    } else {
      throw new Error('image src url is not set');
    }
  }

  private startObserving() {
    this._observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.renderContents(entry.isIntersecting);
        });
      },
      {threshold: [0, 0.1, 0.9, 1]}
    );
    this._observer?.observe(this.element.nativeElement);
  }

  private renderContents(isInView: boolean) {
    if (isInView) {
      if (!this._alreadyRendered) {
        this._alreadyRendered = true;
        this._observer?.disconnect();
        this.loadImage().then();
      }
    }
  }

}
