/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionAnswersAggregate } from './questionAnswersAggregate';
import { UserViewMinimal } from './userViewMinimal';


export interface QuestionnaireInstanceFullView { 
    uid: string;
    questionnaireUid: string;
    start: string;
    end: string;
    status: QuestionnaireInstanceFullView.StatusEnum;
    totalResponses: number;
    answers: Array<QuestionAnswersAggregate>;
    users: Array<UserViewMinimal>;
}
export namespace QuestionnaireInstanceFullView {
    export const StatusEnum = {
        Pending: 'PENDING',
        Activated: 'ACTIVATED',
        Expired: 'EXPIRED'
    } as const;
    export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
}


