/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewQuestionTranslation } from './newQuestionTranslation';
import { NewQuestionOption } from './newQuestionOption';


export interface NewQuestion { 
    sortOrder: number;
    type: NewQuestion.TypeEnum;
    translations: Array<NewQuestionTranslation>;
    options: Array<NewQuestionOption>;
    media?: Array<string>;
}
export namespace NewQuestion {
    export const TypeEnum = {
        MultipleChoice: 'MULTIPLE_CHOICE',
        SingleChoice: 'SINGLE_CHOICE',
        FiveStarRating: 'FIVE_STAR_RATING',
        Open: 'OPEN',
        TenStarRating: 'TEN_STAR_RATING',
        TextOnly: 'TEXT_ONLY'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


