<div class="sos-popup">
  <div class="content">
    <h1>{{'General.SosPopupTitle' | translate}}</h1>
    <div class="location" *ngIf="showNoLocation " aria-label="No Location">
      <ion-icon name="compass-outline" class="location-icon"></ion-icon>
      <div>
        <h2>
          {{'General.SosLocationIsDisabled' | translate}}
        </h2>
      </div>
    </div>
    <div id="countdown" aria-label="Countdown">
      <div class="cd-number" id="countdown-number">{{countdown}}</div>
      <svg>
        <circle r="67.5" cx="71.5" cy="71.5" class="background-circle"></circle>
        <circle *ngIf="counterRunning" r="67.5" cx="71.5" cy="71.5" class="countdown-circle"></circle>
      </svg>
    </div>
    <ng-container>
    <h1>{{'General.Seconds' | translate}}</h1>
    </ng-container>
    <ion-button (click)="cancel()" aria-label="Cancel SOS">{{'General.Cancel' | translate}}</ion-button>
  </div>
</div>
