/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageUpdate { 
    label?: string;
    category?: string;
    ownerOrganization?: string;
    shared?: boolean;
    feedIcon?: string;
    pinned?: boolean;
    draft?: boolean;
    sortOrder?: number;
    pageType?: ContentPageUpdate.PageTypeEnum;
    contentMediumType?: ContentPageUpdate.ContentMediumTypeEnum;
    isDefault?: boolean;
}
export namespace ContentPageUpdate {
    export type PageTypeEnum = 'KNOWLEDGE' | 'ORGANIZATION' | 'FEED_ONLY' | 'ALL';
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE' as PageTypeEnum,
        Organization: 'ORGANIZATION' as PageTypeEnum,
        FeedOnly: 'FEED_ONLY' as PageTypeEnum,
        All: 'ALL' as PageTypeEnum
    };
    export type ContentMediumTypeEnum = 'ARTICLE' | 'PODCAST' | 'VIDEO';
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE' as ContentMediumTypeEnum,
        Podcast: 'PODCAST' as ContentMediumTypeEnum,
        Video: 'VIDEO' as ContentMediumTypeEnum
    };
}


