import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {BehaviorSubject, filter, firstValueFrom} from "rxjs";
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class IonicStorageService {

  private _storage?: Storage;
  private _initialized = new BehaviorSubject<boolean>(false);

  constructor(private storage: Storage) {
    this.init().then();
  }

  async init() {
    if (Capacitor.isNativePlatform()) {
      console.log("Loading CordovaSQLLiteDriver")
      await this.storage.defineDriver(CordovaSQLiteDriver)
    }
    this._storage = await this.storage.create();
    this._initialized.next(true);
  }

  async set(key: string, value: any): Promise<void> {

    const storage = await this.getStorage();
    return await storage.set(key, value);
  }

  async get<T = any>(key: string): Promise<T> {

    const storage = await this.getStorage();
    return await storage.get(key);
  }

  async remove(key: string) {

    const storage = await this.getStorage();
    await storage.remove(key);
  }

  private async getStorage(): Promise<Storage> {
    // Wait for initialization
    if (!this._initialized.value) {
      await firstValueFrom(this._initialized.pipe(filter(x => x)));
    }
    if (!this._storage) throw Error('Storage not initialized, this shouldn\'t happen');

    return this._storage;
  }
}
