/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageMinimalView { 
    uid: string;
    label: string;
    category: string;
    ownerOrganizationUid: string;
    pageType: ContentPageMinimalView.PageTypeEnum;
    contentMediumType: ContentPageMinimalView.ContentMediumTypeEnum;
}
export namespace ContentPageMinimalView {
    export type PageTypeEnum = 'KNOWLEDGE' | 'ORGANIZATION' | 'FEED_ONLY' | 'ALL';
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE' as PageTypeEnum,
        Organization: 'ORGANIZATION' as PageTypeEnum,
        FeedOnly: 'FEED_ONLY' as PageTypeEnum,
        All: 'ALL' as PageTypeEnum
    };
    export type ContentMediumTypeEnum = 'ARTICLE' | 'PODCAST' | 'VIDEO';
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE' as ContentMediumTypeEnum,
        Podcast: 'PODCAST' as ContentMediumTypeEnum,
        Video: 'VIDEO' as ContentMediumTypeEnum
    };
}


