/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewMinimal } from './userViewMinimal';
import { OrganizationViewMinimal } from './organizationViewMinimal';


export interface ReportView { 
    uid: string;
    reportType: ReportView.ReportTypeEnum;
    status: ReportView.StatusEnum;
    createdAt: string;
    resolvedAt?: string;
    organization: OrganizationViewMinimal;
    userUid?: string;
    counselor: UserViewMinimal;
    canWeContact?: boolean;
    contactName?: string;
    contactFunction?: string;
    contactType?: ReportView.ContactTypeEnum;
    contactEmail?: string;
    contactPhone?: string;
    initialContactOn?: string;
    description?: string;
    isResolved: boolean;
    logbookUids?: Array<string>;
}
export namespace ReportView {
    export const ReportTypeEnum = {
        Undesirable: 'UNDESIRABLE',
        Integrity: 'INTEGRITY'
    } as const;
    export type ReportTypeEnum = typeof ReportTypeEnum[keyof typeof ReportTypeEnum];
    export const StatusEnum = {
        Draft: 'DRAFT',
        Sent: 'SENT',
        Read: 'READ',
        Contacted: 'CONTACTED',
        Resolved: 'RESOLVED'
    } as const;
    export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
    export const ContactTypeEnum = {
        Email: 'EMAIL',
        Phone: 'PHONE'
    } as const;
    export type ContactTypeEnum = typeof ContactTypeEnum[keyof typeof ContactTypeEnum];
}


