/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaView } from './mediaView';


export interface OrganizationSlidersView { 
    organizationUid: string;
    slide0?: MediaView;
    slide1?: MediaView;
    slide2?: MediaView;
}

