/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LogbookView { 
    id: string;
    uid: string;
    user: string;
    organization: string;
    description?: string;
    title: string;
    createdAt: string;
    updatedAt: string;
}

