/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportInvolvedPartyView } from './reportInvolvedPartyView';


export interface IntegrityNoteView { 
    reportUid: string;
    createdAt: string;
    updatedAt: string;
    counselorName: string;
    integrityViolatedAt?: string;
    location: string;
    reporterParty?: ReportInvolvedPartyView;
    accusedParty: ReportInvolvedPartyView;
    integrityCategoryCode?: string;
    integrityCategoryRemarks: string;
    remarks: string;
    severity: IntegrityNoteView.SeverityEnum;
    severityRemarks: string;
    requiresInvestigation: boolean;
}
export namespace IntegrityNoteView {
    export type SeverityEnum = 'LOW' | 'MEDIUM' | 'HIGH';
    export const SeverityEnum = {
        Low: 'LOW' as SeverityEnum,
        Medium: 'MEDIUM' as SeverityEnum,
        High: 'HIGH' as SeverityEnum
    };
}


