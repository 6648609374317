import { Injectable, OnDestroy } from '@angular/core';
import {
  catchError,
  combineLatestWith,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
} from 'rxjs';
import { UserService } from '../service/user.service';
import {
  ContentPageCategoryTranslationView,
  OrganizationContentPageTranslationView,
  QuestionnaireInstanceUserView,
} from '../generated-api';
import { PaginatedDataStore } from '../api/PaginatedDataStore';
import { CommunicatorV2Service } from '../api/communicatorV2.service';
import { Pageable } from '../api/modelv2/Pageable';
import { LocaleService } from '../service/locale.service';

export type ContentObject =
  | { type: 'contentPage'; data: OrganizationContentPageTranslationView }
  | { type: 'questionnaire'; data: QuestionnaireInstanceUserView };

@Injectable({
  providedIn: 'root',
})
export class InfoDataService implements OnDestroy {
  newUpdateAvailable: Subject<boolean> = new Subject<boolean>();
  reorderEnabled: boolean = false;

  private lastLoadedLocale?: string;
  private lastLoadedOrganizationUid?: string;
  private pageStore: PaginatedDataStore<OrganizationContentPageTranslationView> =
    new PaginatedDataStore<OrganizationContentPageTranslationView>(
      (page?: number) => {
        return this.userService.currentOrganization.pipe(
          filter(Boolean),
          take(1),
          combineLatestWith(
            this.localeService.backendLocale
          ),
          switchMap(([organization, backendLocale]) => {
            const organizationUid = organization!.uid;

            this.lastLoadedLocale = backendLocale;
            this.lastLoadedOrganizationUid = organizationUid;

            return this.communicatorV2.getNewBackendPages(
              organizationUid,
              page,
              backendLocale
            );
          })
        );
      }
    );

  private _audioDurationCache: { [key: string]: number } = {};

  private localeSubscription?: Subscription;
  private organizationSubscription?: Subscription;

  constructor(
    private communicatorV2: CommunicatorV2Service,
    private userService: UserService,
    private localeService: LocaleService
  ) {
    this.localeSubscription = this.localeService.backendLocale.subscribe((locale) => {
      // If the user changed, we need to reload our data stores
      if (this.lastLoadedLocale !== locale) {
        this.lastLoadedLocale = locale;
        this.pageStore.clearCache();
      }
    });
    this.organizationSubscription =
      this.userService.currentOrganization.subscribe((org) => {
        if (org && this.lastLoadedOrganizationUid !== org.uid) {
          this.pageStore.clearCache();
        }
      });
  }

  ngOnDestroy() {
    this.localeSubscription?.unsubscribe();
    this.organizationSubscription?.unsubscribe();
  }

  getContentPageCategories(
    locale: string
  ): Observable<ContentPageCategoryTranslationView[]> {
    return this.communicatorV2.getContentPageCategories(locale);
  }

  loadAllContent(): Observable<ContentObject[]> {
    // this.pageStore.getData().subscribe((value) => console.log('ContentPages Response:', value));
    return forkJoin({
      contentPages: this.pageStore.getData(),
      elearning: this.getElearnings().pipe(
        map((elearnings) => elearnings?.pages ?? undefined),
        take(1),
        catchError((_) => of(undefined))
      ),
    }).pipe(
      map(({ contentPages, elearning }) => {
        let ret: ContentObject[];

        ret = [
          ...contentPages.map((c) => ({
            type: 'contentPage' as 'contentPage', // wtf typescript!!
            data: c,
          })),
        ];

        if (elearning) {
          for (let el of elearning) {
            ret.push({
              type: 'questionnaire',
              data: el,
            });
          }
        }
        return ret;
      })
    );
  }

  getElearnings(): Observable<Pageable<QuestionnaireInstanceUserView>> {
    return this.userService.currentOrganization.pipe(
      filter((org) => !!org),
      map((org) => org?.uid),
      switchMap((orgUid) => {
        return this.communicatorV2.getQuestionnaires(orgUid ?? '').pipe(
          map((questionnaires) => {
            return {
              ...questionnaires,
              pages: questionnaires.pages.filter(
                (questionnaire) => questionnaire.status !== 'COMPLETED'
              ),
            };
          })
        );
      })
    );
  }

  sortContent(pages: ContentObject[]): ContentObject[] {
    return pages.sort((a: ContentObject, b: ContentObject) => {
      const aIsELearning = a.type == 'questionnaire';
      const bIsELearning = b.type == 'questionnaire';

      if (aIsELearning && !bIsELearning) return -1;
      else if (!aIsELearning && bIsELearning) return 1;
      else if (aIsELearning && bIsELearning) {
        return new Date(b.data.end).getTime() - new Date(a.data.end).getTime();
      }

      // By now, we know that both a and b are content pages
      const aData = a.data as OrganizationContentPageTranslationView;
      const bData = b.data as OrganizationContentPageTranslationView;

      if (aData.pinned !== bData.pinned) {
        return aData.pinned ? -1 : 1;
      }

      if (aData.sortOrder !== bData.sortOrder)
        return aData.sortOrder - bData.sortOrder;

      // If both items are either viewed or not viewed, then sort by updated_at
      if (
        aData?.contentPage?.updatedAt &&
        bData?.contentPage?.updatedAt &&
        aData?.contentPage?.updatedAt !== bData?.contentPage?.updatedAt
      ) {
        return (
          new Date(bData?.contentPage?.updatedAt)?.getTime() -
          new Date(aData?.contentPage?.updatedAt)?.getTime()
        );
      }

      // If updated_at is the same or doesn't exist for either, then sort by created_at
      if (
        aData?.contentPage?.createdAt &&
        bData?.contentPage?.createdAt &&
        aData?.contentPage?.createdAt !== bData?.contentPage?.createdAt
      ) {
        return (
          new Date(bData?.contentPage?.createdAt)?.getTime() -
          new Date(aData?.contentPage?.createdAt)?.getTime()
        );
      }

      // If we're an article, news item, or new type of podcast which has a sort_order, sort by that
      // if ((a.contentPage.pageType === 'articles' || a.contentPage.pageType === 'video' || (a.contentPage.pageType === 'podcast' && a.sort_order !== undefined)) && (b.type === 'articles' || b.type === 'video' || (b.type === 'podcast' && b.sort_order !== undefined))) {
      //   return a.sort_order! - b.sort_order!;
      // }
      //
      // if ((a.contentPage.pageType === 'articles' || a.contentPage.pageType === 'video' || (a.contentPage.pageType === 'podcast' )) {
      //   return a.sort_order! - b.sort_order!;
      // }

      return 0;
    });
  }

  hasBeenViewed(
    item: ContentObject | OrganizationContentPageTranslationView,
    progress: (ContentObject | OrganizationContentPageTranslationView)[]
  ): boolean {
    return progress.some((content) => this.areItemsEqual(item, content));
  }

  areItemsEqual(
    item1: ContentObject | OrganizationContentPageTranslationView,
    item2: ContentObject | OrganizationContentPageTranslationView
  ): boolean {
    if ('type' in item1 && 'type' in item2) {
      if (item1.type !== item2.type) return false;
      if (item1.type == 'contentPage' && item2.type == 'contentPage')
        return item1.data.contentPage.uid == item2.data.contentPage.uid;
      return false;
    }

    if ('type' in item1 || 'type' in item2) return false;
    return (
      item1.contentPage.uid === item2.contentPage.uid &&
      item1.contentPage.pageType === item2.contentPage.pageType
    );
  }

  calculateReadingTime(text?: string): number {
    if (typeof text !== 'string' || !text.trim()) {
      return 0;
    }
    const avgWordsPerMinute: number = 238;
    const words: number = text.split(/\s+/).filter((word) => word).length;
    return Math.ceil(words / avgWordsPerMinute);
  }

  formatTime(seconds: number = 0) {
    return Math.ceil(seconds / 60);
  }

  calcAudioDurationSeconds(audioBlobUrl: string): Promise<number> {
    return new Promise<number>((resolve) => {
      const fileUrl = audioBlobUrl.split('?')[0];
      if (this._audioDurationCache[fileUrl]) {
        return resolve(this._audioDurationCache[fileUrl]);
      }

      const audio = new Audio(audioBlobUrl);
      audio.preload = 'metadata';
      audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        audio.remove();
        this._audioDurationCache[fileUrl] = duration;
        resolve(duration);
      });
    });
  }

  clearAllCaches() {
    console.log('Clearing all caches');
    this.pageStore.clearCache();
    this._audioDurationCache = {};
    this.newUpdateAvailable.next(true);
  }
}
