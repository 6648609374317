/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentPageTranslationView } from './contentPageTranslationView';
import { OrganizationView } from './organizationView';
import { MediaView } from './mediaView';


export interface ContentPageViewFull { 
    uid: string;
    label: string;
    category?: string;
    ownerOrganization: OrganizationView;
    shared: boolean;
    translations: Array<ContentPageTranslationView>;
    feedIcon?: MediaView;
    createdAt: string;
    updatedAt: string;
    pageType: ContentPageViewFull.PageTypeEnum;
    basedOn?: string;
    contentMediumType: ContentPageViewFull.ContentMediumTypeEnum;
    madeByReportApp: boolean;
    isDefault: boolean;
}
export namespace ContentPageViewFull {
    export type PageTypeEnum = 'KNOWLEDGE' | 'ORGANIZATION' | 'FEED_ONLY' | 'ALL';
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE' as PageTypeEnum,
        Organization: 'ORGANIZATION' as PageTypeEnum,
        FeedOnly: 'FEED_ONLY' as PageTypeEnum,
        All: 'ALL' as PageTypeEnum
    };
    export type ContentMediumTypeEnum = 'ARTICLE' | 'PODCAST' | 'VIDEO';
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE' as ContentMediumTypeEnum,
        Podcast: 'PODCAST' as ContentMediumTypeEnum,
        Video: 'VIDEO' as ContentMediumTypeEnum
    };
}


