/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportContactMomentView { 
    reportJournalEntryUid: string;
    contactType: ReportContactMomentView.ContactTypeEnum;
    contactedOn?: string;
    mailContent?: string;
    referredTo?: string;
    durationMinutes?: number;
    presentPersons: Array<ReportContactMomentView.PresentPersonsEnum>;
    presentPersonsRemarks?: string;
    costsKmCents?: number;
    costsMeetingRoomCents?: number;
    costsOtherCents?: number;
}
export namespace ReportContactMomentView {
    export const ContactTypeEnum = {
        Phone: 'PHONE',
        Online: 'ONLINE',
        InPerson: 'IN_PERSON',
        ExternalMail: 'EXTERNAL_MAIL',
        Referral: 'REFERRAL',
        DirectMail: 'DIRECT_MAIL'
    } as const;
    export type ContactTypeEnum = typeof ContactTypeEnum[keyof typeof ContactTypeEnum];
    export const PresentPersonsEnum = {
        Reported: 'REPORTED',
        Accused: 'ACCUSED',
        Counselor: 'COUNSELOR',
        Manager: 'MANAGER',
        Other: 'OTHER'
    } as const;
    export type PresentPersonsEnum = typeof PresentPersonsEnum[keyof typeof PresentPersonsEnum];
}


