/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportContactMomentView { 
    reportJournalEntryUid: string;
    contactType: ReportContactMomentView.ContactTypeEnum;
    contactedOn?: string;
    mailContent?: string;
    referredTo?: string;
    durationMinutes?: number;
    presentPersons: Array<ReportContactMomentView.PresentPersonsEnum>;
    presentPersonsRemarks?: string;
    costsKmCents?: number;
    costsMeetingRoomCents?: number;
    costsOtherCents?: number;
}
export namespace ReportContactMomentView {
    export type ContactTypeEnum = 'PHONE' | 'ONLINE' | 'IN_PERSON' | 'EXTERNAL_MAIL' | 'REFERRAL' | 'DIRECT_MAIL';
    export const ContactTypeEnum = {
        Phone: 'PHONE' as ContactTypeEnum,
        Online: 'ONLINE' as ContactTypeEnum,
        InPerson: 'IN_PERSON' as ContactTypeEnum,
        ExternalMail: 'EXTERNAL_MAIL' as ContactTypeEnum,
        Referral: 'REFERRAL' as ContactTypeEnum,
        DirectMail: 'DIRECT_MAIL' as ContactTypeEnum
    };
    export type PresentPersonsEnum = 'REPORTED' | 'ACCUSED' | 'COUNSELOR' | 'MANAGER' | 'OTHER';
    export const PresentPersonsEnum = {
        Reported: 'REPORTED' as PresentPersonsEnum,
        Accused: 'ACCUSED' as PresentPersonsEnum,
        Counselor: 'COUNSELOR' as PresentPersonsEnum,
        Manager: 'MANAGER' as PresentPersonsEnum,
        Other: 'OTHER' as PresentPersonsEnum
    };
}


