import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent  implements OnInit, OnChanges {

  @Input() currentStep: number = 0;
  @Input() totalSteps: number = 0;

  steps: number[] = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalSteps']) {
      this.steps = [];
      for (let i = 0; i < this.totalSteps; i++) {
        this.steps.push(i);
      }
    }
  }

}
