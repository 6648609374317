/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewReportIntegrity { 
    integrityReportType: NewReportIntegrity.IntegrityReportTypeEnum;
    integrityViolationType: NewReportIntegrity.IntegrityViolationTypeEnum;
    counselorHotlineType: string;
    reasonOfSuspicion: string;
}
export namespace NewReportIntegrity {
    export type IntegrityReportTypeEnum = 'INTERNAL' | 'EXTERNAL';
    export const IntegrityReportTypeEnum = {
        Internal: 'INTERNAL' as IntegrityReportTypeEnum,
        External: 'EXTERNAL' as IntegrityReportTypeEnum
    };
    export type IntegrityViolationTypeEnum = 'UNION_LAW' | 'SOCIAL_WRONGDOING' | 'UNKNOWN';
    export const IntegrityViolationTypeEnum = {
        UnionLaw: 'UNION_LAW' as IntegrityViolationTypeEnum,
        SocialWrongdoing: 'SOCIAL_WRONGDOING' as IntegrityViolationTypeEnum,
        Unknown: 'UNKNOWN' as IntegrityViolationTypeEnum
    };
}


