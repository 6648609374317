/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ForgotCredentialsRequest { 
    email: string;
    type: ForgotCredentialsRequest.TypeEnum;
    target: ForgotCredentialsRequest.TargetEnum;
}
export namespace ForgotCredentialsRequest {
    export type TypeEnum = 'PIN' | 'PASSWORD';
    export const TypeEnum = {
        Pin: 'PIN' as TypeEnum,
        Password: 'PASSWORD' as TypeEnum
    };
    export type TargetEnum = 'CMS' | 'APP';
    export const TargetEnum = {
        Cms: 'CMS' as TargetEnum,
        App: 'APP' as TargetEnum
    };
}


