/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionView } from './questionView';
import { OrganizationViewMinimal } from './organizationViewMinimal';
import { MediaView } from './mediaView';
import { QuestionnaireTranslationView } from './questionnaireTranslationView';


export interface QuestionnaireView { 
    uid: string;
    organization: OrganizationViewMinimal;
    type: QuestionnaireView.TypeEnum;
    feedIcon?: MediaView;
    headerImage?: MediaView;
    maxPoints: number;
    translations: Array<QuestionnaireTranslationView>;
    media?: Array<MediaView>;
    questions: Array<QuestionView>;
    instanceUids: Array<string>;
    isAnonymous?: boolean;
    embedUrl?: string;
}
export namespace QuestionnaireView {
    export type TypeEnum = 'ELEARNING' | 'SURVEY';
    export const TypeEnum = {
        Elearning: 'ELEARNING' as TypeEnum,
        Survey: 'SURVEY' as TypeEnum
    };
}


