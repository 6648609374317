/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserWebQuestionnaireTokenResponse { 
    valid: boolean;
    validUntil?: string;
    targetQuestionnaireInstanceUid?: string;
    targetQuestionnaireUid?: string;
    webQuestionnaireToken?: string;
    userUid?: string;
    userName?: string;
}

