import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {App} from "@capacitor/app";
@Component({
  selector: 'app-enter-pin-modal',
  templateUrl: './enter-pin-modal.component.html',
  styleUrls: ['./enter-pin-modal.component.scss'],
})
export class EnterPinModalComponent  implements OnInit {

  version: string = '?';
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    App.getInfo()
      .then(info => {
        this.version = "v" + info.version + " (" + info.build + ")";
      })
      .catch(err => {
        // Probably, we're web
        console.warn('Unable to get app info', err);
        this.version = 'v? (web)';
      });
  }

  onPinVerified(verified: boolean) {
    if (verified) {
      this.modalCtrl.dismiss().then();
    }
  }

}
