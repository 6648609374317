/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserRegisterRequest { 
    token: string;
    password: string;
    totpCode?: string;
    pin?: string;
    personalEmailAddress: string;
    personalPhoneNumber: string;
    preferredContactMethod?: UserRegisterRequest.PreferredContactMethodEnum;
}
export namespace UserRegisterRequest {
    export const PreferredContactMethodEnum = {
        Phone: 'PHONE',
        Email: 'EMAIL'
    } as const;
    export type PreferredContactMethodEnum = typeof PreferredContactMethodEnum[keyof typeof PreferredContactMethodEnum];
}


