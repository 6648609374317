/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocaleView } from './localeView';
import { OrganizationLocaleView } from './organizationLocaleView';
import { OrganizationCustomPageTypeView } from './organizationCustomPageTypeView';
import { FeatureView } from './featureView';
import { CustomContentPageCategoryView } from './customContentPageCategoryView';
import { OrganizationSlidersView } from './organizationSlidersView';
import { MediaView } from './mediaView';


export interface OrganizationView { 
    uid: string;
    name: string;
    themeColor?: string;
    organizationLocales: Array<OrganizationLocaleView>;
    locales: Array<LocaleView>;
    logo?: MediaView;
    emblem?: MediaView;
    parentOrganizationUid?: string;
    features: Array<FeatureView>;
    childOrganizationUids: Array<string>;
    defaultCounselor?: string;
    slider?: OrganizationSlidersView;
    customContentPageCategories?: Array<CustomContentPageCategoryView>;
    customPageType?: OrganizationCustomPageTypeView;
    isDemo: boolean;
}

