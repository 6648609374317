/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BehaviorType } from './behaviorType';
import { ReportView } from './reportView';


export interface ReportUndesirableView { 
    report: ReportView;
    behaviorType: BehaviorType;
    behaviorOrigin: ReportUndesirableView.BehaviorOriginEnum;
    behaviorDirectedAt: ReportUndesirableView.BehaviorDirectedAtEnum;
}
export namespace ReportUndesirableView {
    export const BehaviorOriginEnum = {
        Internal: 'INTERNAL',
        External: 'EXTERNAL'
    } as const;
    export type BehaviorOriginEnum = typeof BehaviorOriginEnum[keyof typeof BehaviorOriginEnum];
    export const BehaviorDirectedAtEnum = {
        Me: 'ME',
        Bystander: 'BYSTANDER'
    } as const;
    export type BehaviorDirectedAtEnum = typeof BehaviorDirectedAtEnum[keyof typeof BehaviorDirectedAtEnum];
}


