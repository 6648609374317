export function getFileReader(): FileReader {
  // Due to a bug in Capacitor on Android in combination with Angular, we need to use the original FileReader
  // For more details: https://github.com/ionic-team/capacitor/issues/1564
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  return zoneOriginalInstance || fileReader;
}

export function convertBlobToDataUrl(blob: Blob): Promise<string> {
  // console.log("Initializing FileReader");
  return new Promise((resolve, reject) => {
    const reader = getFileReader();
    reader.onloadend = (e: any) => {
      // console.log("onloadend called");
      resolve(e.target.result);
    }
    // reader.onprogress = (e: any) => {
    //   console.log("progress: " + e.loaded + "/" + e.total);
    // }
    reader.onerror = (e: any) => {
      console.error("error: " + e);
      reject(e);
    }
    reader.readAsDataURL(blob);
    // console.log("readAsDataURL called");
  });
}
