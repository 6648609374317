import {Component, OnInit} from '@angular/core';
import {LocalizedAlertService} from "../../../service/localized-alert.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-top-message',
  templateUrl: './top-message.component.html',
  styleUrls: ['./top-message.component.scss'],
})
export class TopMessageComponent implements OnInit {
  message: string = '';
  messageSub!: Subscription

  constructor(private alert: LocalizedAlertService) {
  }

  ngOnInit() {
    this.getTopMessage();
  }

  getTopMessage() {
    this.messageSub = this.alert.topMessage.subscribe((message) => {
      this.message = message.message;
    })
  }

  emptyMessage() {
    this.message = '';
    this.alert.topMessage.next({messageType: '', message: ''});
  }

  ngOnDestroy() {
    if (this.messageSub) this.messageSub.unsubscribe();
  }

}
