import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CommunicatorV2Service} from "../../api/communicatorV2.service";
import {SimpleChanges} from "@angular/core";
export type MediaType = 'audio' | 'image' | 'video';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss'],
})
export class MediaViewerComponent  implements OnInit, OnChanges {

  @Input() mediaType: MediaType = 'image';
  @Input() mediaUrl: string = '';
  mediaDataUrl: string = '';
  constructor(private comV2: CommunicatorV2Service) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    //check simple changes with []
    if(changes['mediaUrl'].previousValue !== changes['mediaUrl'].currentValue) {
      this.downloadMedia();
    }
  }

  downloadMedia(): void  {
    if(this.mediaUrl.startsWith('data:') || this.mediaDataUrl.startsWith('blob:') || this.mediaUrl.startsWith('http:') || this.mediaUrl.startsWith('https:')) {
      this.mediaDataUrl = this.mediaUrl;
      return;
    }
    this.comV2.downloadMedia(this.mediaUrl).subscribe({
      next: (res) => {
        console.log('res', res);
        // turn blob into url that <img> and audio tag can use
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          this.mediaDataUrl = reader.result as string;
        }

      },
      error: _ => {
        console.log('error')
      }
    });
  }

}
