/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterTokenBody { 
    token: string;
    deviceType: RegisterTokenBody.DeviceTypeEnum;
}
export namespace RegisterTokenBody {
    export type DeviceTypeEnum = 'IOS' | 'ANDROID';
    export const DeviceTypeEnum = {
        Ios: 'IOS' as DeviceTypeEnum,
        Android: 'ANDROID' as DeviceTypeEnum
    };
}


