/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserContactInfoView { 
    phoneNumber: string;
    personalEmail: string;
    preferredContactMethod?: UserContactInfoView.PreferredContactMethodEnum;
}
export namespace UserContactInfoView {
    export const PreferredContactMethodEnum = {
        Phone: 'PHONE',
        Email: 'EMAIL'
    } as const;
    export type PreferredContactMethodEnum = typeof PreferredContactMethodEnum[keyof typeof PreferredContactMethodEnum];
}


