/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserRegistrationTokenRequest { 
    token: string;
    scope?: string;
    target?: UserRegistrationTokenRequest.TargetEnum;
}
export namespace UserRegistrationTokenRequest {
    export const TargetEnum = {
        Cms: 'CMS',
        App: 'APP'
    } as const;
    export type TargetEnum = typeof TargetEnum[keyof typeof TargetEnum];
}


