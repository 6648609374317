/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewQuestionnaireTranslation } from './newQuestionnaireTranslation';


export interface NewQuestionnaire { 
    organizationUid: string;
    type: NewQuestionnaire.TypeEnum;
    feedIconUid?: string;
    headerImageUid?: string;
    translations: Array<NewQuestionnaireTranslation>;
    media?: Array<string>;
    isAnonymous?: boolean;
}
export namespace NewQuestionnaire {
    export type TypeEnum = 'ELEARNING' | 'SURVEY';
    export const TypeEnum = {
        Elearning: 'ELEARNING' as TypeEnum,
        Survey: 'SURVEY' as TypeEnum
    };
}


