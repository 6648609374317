/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ForgotCredentialsRequest { 
    email: string;
    type: ForgotCredentialsRequest.TypeEnum;
    target: ForgotCredentialsRequest.TargetEnum;
}
export namespace ForgotCredentialsRequest {
    export const TypeEnum = {
        Pin: 'PIN',
        Password: 'PASSWORD'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
    export const TargetEnum = {
        Cms: 'CMS',
        App: 'APP'
    } as const;
    export type TargetEnum = typeof TargetEnum[keyof typeof TargetEnum];
}


