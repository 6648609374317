<ng-container *ngIf="!isWeb()">
  <div [ngClass]="{'safe-area-bottom': safeArea, 'web' : isWeb()}" class="cta-space">
    <ion-button>Sample</ion-button>
  </div>
</ng-container>

<div [ngClass]="{'safe-area-bottom' : safeArea, 'web' : isWeb()}"
     class="cta">
  <ng-content></ng-content>
</div>
