/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewReportIntegrity { 
    integrityReportType: NewReportIntegrity.IntegrityReportTypeEnum;
    integrityViolationType: NewReportIntegrity.IntegrityViolationTypeEnum;
    counselorHotlineType: string;
    reasonOfSuspicion: string;
}
export namespace NewReportIntegrity {
    export const IntegrityReportTypeEnum = {
        Internal: 'INTERNAL',
        External: 'EXTERNAL'
    } as const;
    export type IntegrityReportTypeEnum = typeof IntegrityReportTypeEnum[keyof typeof IntegrityReportTypeEnum];
    export const IntegrityViolationTypeEnum = {
        UnionLaw: 'UNION_LAW',
        SocialWrongdoing: 'SOCIAL_WRONGDOING',
        Unknown: 'UNKNOWN'
    } as const;
    export type IntegrityViolationTypeEnum = typeof IntegrityViolationTypeEnum[keyof typeof IntegrityViolationTypeEnum];
}


