/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageTranslationBlockUpdate { 
    sortOrder?: number;
    type?: ContentPageTranslationBlockUpdate.TypeEnum;
    body?: string;
    media?: string;
    embedUrl?: string;
}
export namespace ContentPageTranslationBlockUpdate {
    export type TypeEnum = 'TEXT' | 'IMAGE' | 'AUDIO' | 'FILE' | 'EMBED';
    export const TypeEnum = {
        Text: 'TEXT' as TypeEnum,
        Image: 'IMAGE' as TypeEnum,
        Audio: 'AUDIO' as TypeEnum,
        File: 'FILE' as TypeEnum,
        Embed: 'EMBED' as TypeEnum
    };
}


