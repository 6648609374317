import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { UserService } from '../../service/user.service';
import { ImageCacheService } from '../../core/services/image-cache.service';
import { isWeb } from '../../util/IsWeb';
import { BreakpointObserver } from '@angular/cdk/layout';

export type SlideType = 'slide1' | 'slide2' | 'slide3' | 'report1' | 'report2' | 'report3';
export type HeroSlides = { [key in SlideType]: string }

@Component({
  selector: 'app-hero-header',
  templateUrl: './hero-header.component.html',
  styleUrls: ['./hero-header.component.scss'],
})
export class HeroHeaderComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public showSlideType: SlideType = 'slide1';
  @Input({ required: false }) hideOnSmallScreen = false;
  protected isSmallScreen = false;
  protected readonly isWeb = isWeb();
  private slides?: HeroSlides;

  private defaultSlides: HeroSlides = {
    slide1: '/assets/img/brand-img-1.webp',
    slide2: '/assets/img/brand-img-2.webp',
    slide3: '/assets/img/brand-img-3.webp',
    report1: '/assets/img/report1.webp',
    report2: '/assets/img/report2.webp',
    report3: '/assets/img/report3.webp',
  };
  slideSrc: string = this.defaultSlides.slide1;
  orgSub!: Subscription;
  private sliderImagesSub?: Subscription;
  private breakpointSub?: Subscription;

  constructor(
    private user: UserService,
    private imageCache: ImageCacheService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSub = this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((bs) => {
      this.isSmallScreen = bs.matches;
    });
  }

  ngOnInit() {
    this.getNewCompanySliderImages();
  }

  getNewCompanySliderImages(): void {
    this.orgSub = this.user.currentOrganization.subscribe(
      org => {
        this.slides ??= cloneDeep(this.defaultSlides);

        if (org?.slider) {
          if (org.slider.slide0?.url) this.slides.slide1 = org.slider.slide0.url
          if (org.slider.slide1?.url) this.slides.slide2 = org.slider.slide1.url
          if (org.slider.slide2?.url) this.slides.slide3 = org.slider.slide2.url
        }

        this.slideSrc = this.slides?.[this.showSlideType] ?? this.defaultSlides[this.showSlideType];
        this.preloadSliderImages().then();
      }
    );

  }

  async preloadSliderImages() {
    if (!this.slides) return;

    // We only preload the main slides, the "report" slides are less likely to be needed
    const slides = [this.slides.slide1, this.slides.slide2, this.slides.slide3];
    for (const slide of slides) {
      await this.imageCache.preloadImage(slide, { includeQueryString: false });
    }
  }

  ngOnDestroy(): void {
    this.sliderImagesSub?.unsubscribe();
    this.breakpointSub?.unsubscribe()
    this.orgSub?.unsubscribe();
  }
}
