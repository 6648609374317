<div class="popup safe-area-m-top animate__animated animate__fadeIn"
     [ngClass]="{'animate__fadeOut': isClosing}"
>
  <div class="popup-inner max-w-screen-md m-auto" (click)="close()">
    <div class="header">
      <div class="close" (click)="close()" aria-label="close">
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
    <div class="content safe-area-m-bottom">
      <div class="title"><h1><b [innerHTML]="popUpTitle">Over Dit logboek</b></h1></div>
      <div [innerHTML]="popUpMessage" aria-label="Popup Content">

        <h2><b>Subtitel</b></h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
        <h2><b>Subtitel</b></h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
    </div>
  </div>
</div>
