/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageUpdate { 
    label?: string;
    category?: string;
    ownerOrganization?: string;
    shared?: boolean;
    feedIcon?: string;
    pinned?: boolean;
    draft?: boolean;
    sortOrder?: number;
    pageType?: ContentPageUpdate.PageTypeEnum;
    contentMediumType?: ContentPageUpdate.ContentMediumTypeEnum;
    isDefault?: boolean;
}
export namespace ContentPageUpdate {
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE',
        Organization: 'ORGANIZATION',
        FeedOnly: 'FEED_ONLY',
        All: 'ALL'
    } as const;
    export type PageTypeEnum = typeof PageTypeEnum[keyof typeof PageTypeEnum];
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE',
        Podcast: 'PODCAST',
        Video: 'VIDEO'
    } as const;
    export type ContentMediumTypeEnum = typeof ContentMediumTypeEnum[keyof typeof ContentMediumTypeEnum];
}


