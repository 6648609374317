import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';

/**
 * All alerts that support localization
 */
export type AlertMessage =
  | 'ReportSent'
  | 'UserLocaleChanged'
  | 'PasswordChanged'
  | 'UnknownEmailForgotPassword'
  | 'InvalidResetToken'
  | 'ProfileUpdated'
  | 'PinCodeHasBeenReset'
  | 'PinSet'
  | 'ErrorSettingPin'
  | 'SessionExpired'
  | 'InvalidInput'
  | 'InvalidAccessCode'
  | 'InvalidCredentials'
  | 'InvalidPinCode'
  | 'FileTooBig'
  | 'InvalidAudioFileType'
  | 'UnsavedChanges'
  | 'SaveConcept'
  | 'NewAppUpdateAvailable'
  | 'FeedbackSuccess'
  | 'FeedbackError'
  | 'Default';

export type TopMessage =
  | 'SOS'
  | 'CreateLog'
  | 'SentSuccessfully'
  | 'RequestContact'
  | 'IntegrityReportSent'
  | 'UndesirableBehaviorReportSent'
  | 'ReportSent'
  | 'Default';

export interface AlertTranslation {
  header: string;
  message: string;
  cancel?: string;
  confirm?: string;
  delete?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocalizedAlertService {
  public topMessage: BehaviorSubject<{ messageType: string; message: string }> = new BehaviorSubject<{
    messageType: string;
    message: string;
  }>({ messageType: '', message: '' });

  constructor(private translate: TranslateService, private alertService: AlertController) {}

  showAlert(message: AlertMessage = 'Default', onClosed?: () => void) {
    this.translate.get([`Alert.${message}.Title`, `Alert.${message}.Message`, 'Alert.Default.Title', 'Alert.Default.Message', 'General.OK']).subscribe((t) => {
      this.alertService
        .create({
          header: t[`Alert.${message}.Title`] ?? t['Alert.Default.Title'] ?? 'Error',
          message: t[`Alert.${message}.Message`] ?? t['Alert.Default.Message'] ?? 'An error occurred',
          buttons: [t[`General.OK`] ?? 'Ok'],
        })
        .then((a) => {
          this.updateOnTranslationChange(a, message);
          a.present().then();
          if (onClosed) {
            a.onDidDismiss().then(onClosed);
          }
        });
    });
  }

  alertWithConfirmationOptions(message: AlertMessage = 'Default', confirmHandler: () => void, cancelHandler: () => void) {
    this.getTranslation(message, ['cancel', 'confirm']).then((t) => {
      this.alertService
        .create({
          header: t.header ?? 'Error',
          message: t.message ?? 'An error occurred',
          buttons: [
            {
              text: t.cancel ?? 'Cancel',
              role: 'cancel',
              handler: cancelHandler,
            },
            {
              text: t.confirm ?? 'Ok',
              role: 'confirm',
              handler: confirmHandler,
            },
          ],
        })
        .then(async (a) => {
          this.updateOnTranslationChange(a, message);
          await a.present();
        });
    });
  }

  conceptAlert(saveHandler: () => void | Promise<void>, discardHandler: () => void | Promise<void>, deleteHandler?: () => void | Promise<void>) {
    this.getTranslation('SaveConcept', ['cancel', 'confirm', 'delete']).then((t) => {
      const buttons = [
        {
          text: t.cancel ?? 'Cancel',
          role: 'cancel',
          handler: discardHandler,
        },
        {
          text: t.confirm ?? 'Ok',
          role: 'confirm',
          handler: saveHandler,
        },
      ];

      if (deleteHandler) {
        buttons.push({
          text: t.delete ?? 'Delete',
          role: 'delete',
          handler: deleteHandler,
        });
      }

      this.alertService
        .create({
          header: t.header ?? 'Error',
          message: t.message ?? 'An error occurred',
          backdropDismiss: false,
          buttons,
        })
        .then(async (a) => {
          this.updateOnTranslationChange(a, 'SaveConcept');
          await a.present();
        });
    });
  }

  hideAlert() {
    this.alertService.dismiss().then();
  }

  showTopMessage(messageType: string, message: TopMessage) {
    // set this.topMessage to the message(with translation) and type for 10 seconds, after 10 seconds set it to empty
    this.translate.get([`TopMessage.${message}`]).subscribe((t) => {
      this.topMessage.next({
        messageType: messageType,
        message: t[`TopMessage.${message}`] ?? t['TopMessage.Default.Message'] ?? 'An error occurred',
      });
    });
    setTimeout(() => {
      this.topMessage.next({ messageType: '', message: '' });
    }, 10000);
  }

  private getTranslation(message: AlertMessage, buttons: ('cancel' | 'confirm' | 'delete')[]): Promise<AlertTranslation> {
    return firstValueFrom(this.streamTranslation(message, buttons));
  }

  private streamTranslation(message: AlertMessage, buttons: ('cancel' | 'confirm' | 'delete')[]): Observable<AlertTranslation> {
    const keys = [`Alert.${message}.Title`, `Alert.${message}.Message`, 'Alert.Default.Title', 'Alert.Default.Message'];
    if (buttons.includes('cancel')) {
      keys.push(`Alert.${message}.Cancel`, 'General.Cancel');
    }
    if (buttons.includes('confirm')) {
      keys.push(`Alert.${message}.Confirm`, 'General.Confirm');
    }
    if (buttons.includes('delete')) {
      keys.push(`Alert.${message}.Delete`, 'General.Delete');
    }

    return this.translate.stream(keys).pipe(
      map((translations) => {
        const alertTranslation: AlertTranslation = {
          header: translations[`Alert.${message}.Title`] ?? translations['Alert.Default.Title'] ?? 'Error',
          message: translations[`Alert.${message}.Message`] ?? translations['Alert.Default.Message'] ?? 'An error occurred',
        };
        if (buttons.includes('cancel')) alertTranslation.cancel = translations[`Alert.${message}.Cancel`] ?? translations['General.Cancel'];
        if (buttons.includes('confirm')) alertTranslation.confirm = translations[`Alert.${message}.Confirm`] ?? translations['General.Confirm'];
        if (buttons.includes('delete')) alertTranslation.delete = translations[`Alert.${message}.Delete`] ?? translations['General.Delete'];
        return alertTranslation;
      })
    );
  }

  private updateOnTranslationChange(alert: HTMLIonAlertElement, message: AlertMessage) {
    const buttonTypes = alert.buttons
      .map((b) => {
        if (typeof b === 'string') return;
        if (b.role === 'cancel') return 'cancel';
        if (b.role === 'confirm') return 'confirm';
        return undefined;
      })
      .filter((b) => b !== undefined) as ('cancel' | 'confirm')[];

    const langChangeSubscription = this.streamTranslation(message, buttonTypes).subscribe(async () => {
      const translations = await this.getTranslation(message, buttonTypes);
      alert.header = translations.header;
      alert.message = translations.message;
      alert.buttons.forEach((b) => {
        if (typeof b === 'string') return;
        if (b.role === 'cancel' && translations.cancel) b.text = translations.cancel;
        if (b.role === 'confirm' && translations.confirm) b.text = translations.confirm;
      });
    });
    alert.onDidDismiss().then(() => {
      console.log('dismissed');
      langChangeSubscription?.unsubscribe();
    });
  }
}
