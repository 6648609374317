import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  public currentScrollPosition: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  public currentSelectedMenuTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Newsfeed')
  public showSosButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  private routerSub!: Subscription
  private translateSub!: Subscription

  constructor(private router: Router, private translate: TranslateService) {
  }


  setNavigationTitle(urlCheck: string, translateName: string) {
    this.routerSub = this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) return;
      if (event.url !== urlCheck) return;

      this.translateSub = this.translate.get(translateName).subscribe(res => {
        if (res) this.currentSelectedMenuTitle.next(res);
      });
    });
  }

  ngOnDestroy() {
    if (this.routerSub) this.routerSub.unsubscribe();
    if (this.translateSub) this.translateSub.unsubscribe();
  }
}
