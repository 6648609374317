/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionAnswersAggregate } from './questionAnswersAggregate';
import { UserViewMinimal } from './userViewMinimal';


export interface QuestionnaireInstanceFullView { 
    uid: string;
    questionnaireUid: string;
    start: string;
    end: string;
    status: QuestionnaireInstanceFullView.StatusEnum;
    totalResponses: number;
    answers: Array<QuestionAnswersAggregate>;
    users: Array<UserViewMinimal>;
}
export namespace QuestionnaireInstanceFullView {
    export type StatusEnum = 'PENDING' | 'ACTIVATED' | 'EXPIRED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Activated: 'ACTIVATED' as StatusEnum,
        Expired: 'EXPIRED' as StatusEnum
    };
}


