/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportInvolvedPartyView { 
    name?: string;
    gender?: ReportInvolvedPartyView.GenderEnum;
    age?: number;
    function?: string;
    department?: string;
    type: ReportInvolvedPartyView.TypeEnum;
}
export namespace ReportInvolvedPartyView {
    export const GenderEnum = {
        Male: 'MALE',
        Female: 'FEMALE',
        Other: 'OTHER'
    } as const;
    export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];
    export const TypeEnum = {
        Individual: 'INDIVIDUAL',
        Group: 'GROUP',
        Organization: 'ORGANIZATION'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


