import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CommunicatorV2Service} from "../../../api/communicatorV2.service";

@Component({
  selector: 'app-media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.scss']
})
export class MediaImageComponent implements OnInit, OnChanges {
  @Input({required: true}) uid!: string;
  @Input() url?: string;
  @Input() classList: string | undefined;
  @Input() allowFallback: boolean = true;
  @Input() requestWidth?: number;
  @Input() width?: number;
  @Input() height?: number;

  src: string | undefined | null

  constructor(private api: CommunicatorV2Service) {
  }

  ngOnInit(): void {
    this.src ??= this.url;
    if (this.src) return;

    this.getImageById()
  }

  getImageById(): void {
    if (!this.uid) return
    this.api.getImageById(this.uid, this.requestWidth).subscribe({
      next: (value: Blob) => this.src = URL.createObjectURL(value)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['url']) this.src = changes['url'].currentValue
    if (changes['uid'] && !this.url) this.getImageById();
  }
}
