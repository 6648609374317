import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './service/auth.service';
import { TermsPage } from './terms/terms.page';
import { FeedbackComponent } from './feedback/feedback.component';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './service/auth.interceptor';
import { CoreModule } from './core/core.module';
import { IonicStorageModule, StorageConfig } from '@ionic/storage-angular';
import { IonicStorageService } from './service/ionic-storage.service';
import { Capacitor } from '@capacitor/core';
import { Drivers } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ReportModule } from './report/report.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function storageConfig(): StorageConfig {
  if (!Capacitor.isNativePlatform()) {
    return { driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] };
  }

  return {
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
  };
}

initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, TermsPage, FeedbackComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    IonicStorageModule.forRoot(storageConfig()),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    FormsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    IonicStorageService,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
