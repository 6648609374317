/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportContactMomentUpdate } from './reportContactMomentUpdate';


export interface ReportJournalEntryUpdate { 
    notes?: string;
    nextSteps?: string;
    contactMoment?: ReportContactMomentUpdate;
}

