import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss'],
})
export class StepperHeaderComponent implements OnInit {

  @Input() currentStep: number = 0
  @Input() totalSteps: number = 0

  @Output() back = new EventEmitter<MouseEvent>();
  @Output() close = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit() {}

  onBackClicked($event: MouseEvent) {
    this.back.emit($event)
  }

  onCloseClicked($event: MouseEvent) {
    this.close.emit($event);
  }
}
