import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-selector',
  templateUrl: './toggle-selector.component.html',
  styleUrls: ['./toggle-selector.component.scss'],
})
export class ToggleSelectorComponent  implements OnInit {

  @Input() value1: string = "yes";
  @Input() value2: string = "no";
  @Input() label1: string = "Yes";
  @Input() label2: string = "No";
  @Input() selected?: string;
  @Output() selectedChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  setValue(value: string) {
    this.selected = value;
    this.selectedChange.emit(value);
  }
}
