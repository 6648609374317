/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QuestionnaireInstanceView { 
    uid: string;
    questionnaireUid: string;
    start: string;
    end: string;
    status: QuestionnaireInstanceView.StatusEnum;
    userUids: Array<string>;
}
export namespace QuestionnaireInstanceView {
    export const StatusEnum = {
        Pending: 'PENDING',
        Activated: 'ACTIVATED',
        Expired: 'EXPIRED'
    } as const;
    export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
}


