import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, filter, firstValueFrom, lastValueFrom, map, Observable, of, share, switchMap, tap, throwError } from 'rxjs';
import { decodeJwt } from 'jose';
import { LocalizedAlertService } from './localized-alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TokenManagerService } from './token-manager.service';
import { ResponseCache } from '../api/ResponseCache';
import { IonicStorageService } from './ionic-storage.service';
import { fromIterable, fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ModalController, NavController } from '@ionic/angular';
import { EnterPinModalComponent } from '../auth/enter-pin-modal/enter-pin-modal.component';
import { CommunicatorV2Service } from '../api/communicatorV2.service';
import { LoginResponse, UserMeView, UserRegistrationTokenResponse } from '../generated-api';
import { isWeb } from '../util/IsWeb';
import RequiredActionEnum = LoginResponse.RequiredActionEnum;

const SETUP_TOKEN_KEY = 'setupToken';
const SETUP_COMPLETE_KEY = 'setupComplete';

const LOGGED_IN_USER_KEY = 'loggedInUser';
const DEVICE_ID_KEY = 'device_id';
const LAST_LOGIN_KEY = 'lastLogin';

export interface ProfileSetup {
  password?: string;
  passwordConfirmation?: string;
  pin?: string;
  pinConfirmation?: string;
  contactType?: 'PHONE' | 'EMAIL' | undefined;
  phone?: string;
  personalEmail?: string;
  termsAgreed?: boolean;
  token?: string;
}

export enum AuthState {
  /**
   * When auth is not yet initialized (state is not known yet)
   */
  INITIALIZING = 'INITIALIZING',
  /**
   * The app is first started
   */
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  /**
   * The user logged in user their access code, but has not yet set a pin & password (initial setup)
   */
  IN_SETUP = 'IN_SETUP',
  /**
   * The user has completed the initial setup, but needs to log in (again)
   */
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  /**
   * The user is logged in, but needs to verify their pin code
   */
  REQUIRE_PIN = 'REQUIRE_PIN',
  /**
   * The user is fully logged in
   */
  LOGGED_IN = 'LOGGED_IN',
  /**
   * The user is logged in with a pin code (secure mode)
   */
  LOGGED_IN_WITH_PIN = 'LOGGED_IN_WITH_PIN',
  /**
   * The user has logged in (either with SAML or with an account created in the CMS), but hasn't set a pin yet. The first step is to set a pin
   */
  CONFIGURE_PIN = 'CONFIGURE_PIN',
  /**
   * The user has logged in (either with SAML or with an account created in the CMS), but needs to configure some personal details.
   */
  CONFIGURE_PROFILE = 'CONFIGURE_PROFILE',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedInUser = new BehaviorSubject<UserMeView | undefined>(undefined);
  public state: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(AuthState.INITIALIZING);
  /**
   * Token used during initial setup (retrieved after "login" with access code). This token is used later to set a pin etc.
   * @private
   */
  private setupToken?: string;
  private meCache?: ResponseCache<UserMeView>;
  private _pincodeTimer?: any;

  constructor(
    private comV2: CommunicatorV2Service,
    private tokenManager: TokenManagerService,
    private alert: LocalizedAlertService,
    private ionicStorage: IonicStorageService,
    private modalController: ModalController,
    private nav: NavController
  ) {
    this.initialize().then(() => {
      // After the initialization is done, we can start listening to the token stream. If the token stops being valid, we need to show the pin code modal
      this.tokenManager
        .stream()
        .pipe(
          // null token means the token expired
          filter((token) => !token)
        )
        .subscribe(() => {
          // We're going to show a pin code modal, but only if we're logged in. The pin code will be entered to restore the session
          if (this.state.value !== AuthState.LOGGED_IN && this.state.value !== AuthState.LOGGED_IN_WITH_PIN) return;

          console.log('token expired, requiring pin');
          if (this.modalController.getTop() !== undefined) {
            console.log('modal already open');
            return;
          } else {
            console.log('opening modal');
          }
          this.modalController
            .create({
              component: EnterPinModalComponent,
              backdropDismiss: false,
              canDismiss: (_) => firstValueFrom(this.tokenManager.stream().pipe(map((token) => !!token))),
            })
            .then((modal) => {
              modal.present().then();
            });
        });
    });
  }

  isLoggedIn(): Observable<boolean> {
    return this.state.pipe(map((s) => s === AuthState.LOGGED_IN || s === AuthState.LOGGED_IN_WITH_PIN));
  }

  loginWithAccessCode(accessCode: string): Observable<boolean> {
    return this.comV2.verifyRegistrationToken(accessCode).pipe(map((result) => this.handleAccessCodeLogin(result, accessCode)));
  }

  registerProfile(profile: ProfileSetup): Observable<void> {
    return this.comV2
      .activationRegister({
        token: this.setupToken!!,
        password: profile.password!!,
        pin: profile.pin!!,
        personalEmailAddress: profile.personalEmail!!,
        personalPhoneNumber: profile.phone!!,
        preferredContactMethod: profile.contactType!!,
      })
      .pipe(
        map(() => {
          this.state.next(AuthState.NOT_LOGGED_IN);
          localStorage.setItem(SETUP_COMPLETE_KEY, '1');

          this.clearSetup();
        })
      );
  }

  login(email: string, password: string): Observable<RequiredActionEnum | undefined> {
    return this.comV2.login(email, password).pipe(
      map((result) => {
        this.handleSuccessfulLogin(result, false);
        this.registerDevice();
        return result.requiredAction;
      })
    );
  }

  /**
   * Logs the user out.
   * Note that this method will never fail. If the logout fails in the backend, it will still clear the credentials effectively logging the user out
   */
  logout() {
    // We make an outer observable here, so that we can clear the login after the logout request finishes. We don't care if it actually completes, we just need it to finish.
    return fromIterable([1]).pipe(
      // Having catchError in the inner observable pipeline will keep outer observable live [i.e. keep emitting the new value even if inner observable throws exception].
      switchMap(() => fromPromise(this.ionicStorage.get<string>(DEVICE_ID_KEY))),
      switchMap((deviceId) =>
        this.comV2
          .logout({
            target: isWeb() ? 'web-app' : 'app',
            deviceId: deviceId,
          })
          .pipe(
            catchError((err) => {
              console.warn(`Swallowing error during logout: ${err?.message}`, err);
              return of(EMPTY);
            })
          )
      ),
      switchMap((_) => fromPromise(this.clearLogin()))
      // delay(10000),
    );
  }

  async clearLogin() {
    console.log('clearing login');
    this.state.next(AuthState.NOT_LOGGED_IN);
    this.tokenManager.clearAccessToken();
    this.tokenManager.clearRefreshToken();
    await this.ionicStorage.remove(DEVICE_ID_KEY).then();
    this.meCache = undefined;
    this.loggedInUser.next(undefined);
    localStorage.removeItem(LOGGED_IN_USER_KEY);
    localStorage.removeItem(SETUP_COMPLETE_KEY);
  }

  verifyPin(pinCode: string): Observable<Required<LoginResponse>> {
    return fromPromise(this.ionicStorage.get<string>(DEVICE_ID_KEY)).pipe(
      switchMap((deviceId) => {
        return this.comV2.loginWithPinCode(deviceId, pinCode);
      }),
      map((result) => {
        console.log('logged in with pin');
        // If the pin was verified, we get a new (more secure) access token we can use
        this.handleSuccessfulLogin(result, true);

        return result;
      })
    );
  }

  resetPincodeTimer() {
    if (this._pincodeTimer) {
      clearTimeout(this._pincodeTimer);
    }
    this._pincodeTimer = setTimeout(() => {
      this.state.next(AuthState.LOGGED_IN);
      console.log('set auth state back to login');
    }, 5 * 60 * 1000);
  }

  setPin(token: string, newPin: string): Observable<void> {
    return this.comV2.resetCredentials({
      token,
      pin: newPin,
    });
  }

  forgotPinCode(email: string): Observable<void> {
    return this.comV2.forgotCredentials(email, 'PIN');
  }

  getMe(): Observable<UserMeView> {
    if (this.meCache) {
      return this.meCache.getData();
    } else {
      return throwError(() => new HttpErrorResponse({ status: 401 }));
    }
  }

  reloadLoggedInUser(): Observable<UserMeView> {
    const reload = this.meCache!!.reload().pipe(
      share(),
      tap((user) => {
        this.loggedInUser.next(user);
        if (this.state.value === AuthState.CONFIGURE_PROFILE && user.contactInfo && (user.contactInfo.personalEmail || user.contactInfo.phoneNumber)) {
          this.state.next(AuthState.LOGGED_IN);
        }
      }));
    reload.subscribe();
    return reload;
  }

  forgotPassword(email: string) {
    return this.comV2.forgotCredentials(email, 'PASSWORD');
  }

  setPasswordWithResetToken(token: string, password: string, repeatPassword: string) {
    if (password !== repeatPassword) {
      return throwError(() => new Error('Passwords do not match'));
    }
    return this.comV2.resetCredentials({
      token: token.trim(),
      password: password,
    });
  }

  private async initialize() {
    this.tokenManager.onSuccessfulRefreshTokenLogin.subscribe((response) => {
      // TODO Configure a device ID if we don't have one?
      if (response.requiredAction == RequiredActionEnum.Pin) {
        this.state.next(AuthState.CONFIGURE_PIN);
      }
      this.ionicStorage.get(DEVICE_ID_KEY).then((deviceId) => {
        if (!deviceId) {
          this.registerDevice();
        }
      }).catch(console.error);
    });
    const accessToken = await this.tokenManager.getAccessToken();
    if (accessToken) {
      console.log('Detected access token, user is logged in. Initializing user');
      this.initLoggedInUser();
      if (this.state.value !== AuthState.CONFIGURE_PIN) {
        this.state.next(AuthState.LOGGED_IN);
      }
      return;
    }

    // The access token is not set or was expired, but if we have a device_id, the user can still log in with their pin
    const deviceId = await this.ionicStorage.get(DEVICE_ID_KEY);
    const lastLogin = await this.ionicStorage.get(LAST_LOGIN_KEY);
    console.log(`Read device Id: ${deviceId} and last login ${lastLogin}`);
    if (deviceId && lastLogin) {
      console.log('Detected device ID, pin required for login');
      this.state.next(AuthState.REQUIRE_PIN);
      return;
    }
    if (deviceId && !lastLogin) {
      await this.ionicStorage.remove(DEVICE_ID_KEY);
      this.state.next(AuthState.NOT_LOGGED_IN);
      return;
    }

    const setupComplete = localStorage.getItem(SETUP_COMPLETE_KEY);
    if (setupComplete) {
      this.state.next(AuthState.NOT_LOGGED_IN);
    } else {
      const setupToken = localStorage.getItem(SETUP_TOKEN_KEY);
      if (setupToken) {
        // We check if the token is still valid (for more than a minute), otherwise we need to log in again
        try {
          const decodedSetupToken = decodeJwt(setupToken);
          if (!decodedSetupToken.exp || decodedSetupToken.exp * 1000 < Date.now() + 60 * 1000) {
            this.alert.showAlert('SessionExpired');
            this.clearSetup();

            this.state.next(AuthState.NOT_ACTIVATED);
          } else {
            this.setupToken = setupToken;
            this.state.next(AuthState.IN_SETUP);
          }
        } catch (e) {
          // Apparently the token is invalid
          console.error('Setup token was set but is invalid. Are you tinkering with our code?', e);
          this.clearSetup();
          this.state.next(AuthState.NOT_ACTIVATED);
        }
      } else {
        this.state.next(AuthState.NOT_ACTIVATED);
      }
    }
  }

  private initLoggedInUser() {
    this.meCache = new ResponseCache<UserMeView>(() => {
      return this.comV2.getUserMe().pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 401) {
            // Probably the access token is expired. If we know a device Id, we can just ask the user to login using their pin
            this.ionicStorage.get(DEVICE_ID_KEY).then((deviceId) => {
              if (deviceId) {
                this.state.next(AuthState.REQUIRE_PIN);
              } else {
                this.logout().subscribe(() => this.nav.navigateRoot(['/']).then());
              }
            });
          }
          return throwError(() => e);
        })
      );
    }, 30 * 60 * 1000);
    this.meCache.getData().subscribe((user) => {
      this.ionicStorage.set(LAST_LOGIN_KEY, Date.now().valueOf()).then();
      this.loggedInUser.next(user);
    });
  }

  private clearSetup() {
    this.setupToken = undefined;
    localStorage.removeItem(SETUP_TOKEN_KEY);
    this.tokenManager.clearActivationToken();
  }

  private handleAccessCodeLogin(result: UserRegistrationTokenResponse, accessCode: string): boolean {
    // If we got here, the activation token was accepted. We store the token for later use
    this.setupToken = accessCode;

    // We also store it in local storage, in case the user decides to close the app and come back later
    localStorage.setItem(SETUP_TOKEN_KEY, this.setupToken!!);

    this.tokenManager.setActivationToken(result.activationToken!!);
    this.state.next(AuthState.IN_SETUP);

    return true;
  }

  /**
   * Used to configure the pin for users who don't have a PIN configured yet. If you want to update the users pin, use {@link setPin}
   * @param pin The pin to set.
   */
  async configurePin(pin: string): Promise<AuthState> {
    // Note that if this throws an error, the calling method is responsible for handling it
    await lastValueFrom(this.comV2.setPin(pin, undefined));

    const me = await firstValueFrom(this.getMe());
    if (!me.contactInfo || (!me.contactInfo.personalEmail && !me.contactInfo.phoneNumber)) {
      // Use needs to configure their profile
      this.state.next(AuthState.CONFIGURE_PROFILE);
    } else {
      this.state.next(AuthState.LOGGED_IN);
    }
    return this.state.value;
  }

  private registerDevice() {
    this.comV2.registerDevice().subscribe({
      next: (r) => {
        this.ionicStorage.set(DEVICE_ID_KEY, r.deviceId).then();
      },
      error: console.error,
    });
  }

  /**
   * Handles successful login by setting access token, initializing user, updating state, and registering device.
   *
   * @param {LoginResponse} result - the login response object
   * @param {boolean} withPin - flag indicating if login was with a pin
   */
  private handleSuccessfulLogin(result: LoginResponse, withPin: boolean) {
    this.tokenManager.handleLoginResponse(result);

    if (!this.meCache) {
      this.initLoggedInUser();
    }

    this.state.next(withPin ? AuthState.LOGGED_IN_WITH_PIN : AuthState.LOGGED_IN);
  }
}
