import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../service/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicatorV2Service } from '../api/communicatorV2.service';
import { UserService } from '../service/user.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'translateOverride',
  pure: false,
})
export class TranslateOverridePipe implements PipeTransform, OnDestroy {
  private lastLocale = '';
  private backendLocale?: string;
  private cache = new Map<string, string>();

  private localeSub?: Subscription;

  constructor(
    private localeService: LocaleService,
    private translateService: TranslateService,
    private api: CommunicatorV2Service,
    private userService: UserService
  ) {
    this.localeSub = this.localeService.backendLocale.subscribe(locale => {
      this.cache.clear();
      this.backendLocale = locale;
    });
  }

  ngOnDestroy() {
    this.localeSub?.unsubscribe();
  }

  transform(value: string, ...args: unknown[]) {
    if (this.lastLocale !== (this.backendLocale ?? this.localeService.locale.value)) {
      this.lastLocale = (this.backendLocale ?? this.localeService.locale.value);
      this.cache.clear();
    }

    if (!this.cache.has(value)) {
      this.loadTranslationOverride(value);
    }

    return this.cache.get(value) ?? this.translateService.instant(value, args);
  }

  private loadTranslationOverride(key: string) {
    const locale = this.backendLocale ?? this.localeService.locale.value;
    if (!locale) return;

    const organization = this.userService.currentOrganization.value;
    if (!organization) return;

    return this.api
      .getOrganizationTranslationOverrides(organization.uid)
      .subscribe((value) => {
        if (!value) return;

        const override = value[locale];
        if (!override || !override[key]) return;

        this.cache.set(key, override[key]);
      });
  }
}
