/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContentPageNew { 
    label: string;
    category?: string;
    ownerOrganization: string;
    shared: boolean;
    pageType: ContentPageNew.PageTypeEnum;
    feedIcon?: string;
    contentMediumType: ContentPageNew.ContentMediumTypeEnum;
    customContentPageCategory?: string;
}
export namespace ContentPageNew {
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE',
        Organization: 'ORGANIZATION',
        FeedOnly: 'FEED_ONLY',
        All: 'ALL'
    } as const;
    export type PageTypeEnum = typeof PageTypeEnum[keyof typeof PageTypeEnum];
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE',
        Podcast: 'PODCAST',
        Video: 'VIDEO'
    } as const;
    export type ContentMediumTypeEnum = typeof ContentMediumTypeEnum[keyof typeof ContentMediumTypeEnum];
}


