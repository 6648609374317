import {Component, OnInit} from '@angular/core';
import {AppUpdate, AppUpdateAvailability, AppUpdateInfo} from '@capawesome/capacitor-app-update';
import {Platform} from '@ionic/angular';
import {LocalizedAlertService} from '../../../service/localized-alert.service';

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent  implements OnInit {
  public isUpdateAvailable: boolean = true;
  appUpdateInfo: AppUpdateInfo | undefined;

  constructor(private platform: Platform, private alert: LocalizedAlertService) {
  }

  ngOnInit() {
    this.checkForUpdate().then();
  }

  async checkForUpdate() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    AppUpdate.getAppUpdateInfo().then(appUpdateInfo => {
      this.isUpdateAvailable = appUpdateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
      this.appUpdateInfo = appUpdateInfo;
      if (this.isUpdateAvailable) {
        this.alert.alertWithConfirmationOptions('NewAppUpdateAvailable', () => {
          this.openAppStore().then();
        }, () => {
          this.isUpdateAvailable = false;
        });
      } else {
        console.log('No update available');
      }
    });
  }

  public async openAppStore(): Promise<void> {
    if (!this.platform.is('capacitor')) {
      return;
    }
    await AppUpdate.openAppStore();
  }

}
