export enum FeedbackSubject {
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  SUGGESTION_APP_USAGE = 'SUGGESTION_APP_USAGE',
  SUGGESTION_CONTENT = 'SUGGESTION_CONTENT',
  OTHER = 'OTHER',
}

export interface FeedbackAttachment {
  file: Blob,
  name: string,
}

export interface Feedback {
  name: string;
  email: string;
  organization: string | null;
  description: string;
  subject: FeedbackSubject;
  attachments: FeedbackAttachment[];
  hCaptchaResponse?: string;
}
