import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value - (hours * 3600)) / 60);
    const seconds: number = Math.floor(value - (hours * 3600) - (minutes * 60));

    let hourStr = (hours < 10) ? "0" + hours : hours.toString();
    let minuteStr = (minutes < 10) ? "0" + minutes : minutes.toFixed(0).toString();
    let secondStr = (seconds < 10) ? "0" + seconds : seconds.toFixed(0).toString();

    if (hours > 0) {
      return `${hourStr}:${minuteStr}:${secondStr}`;
    } else {
      return `${minuteStr}:${secondStr}`;
    }
  }
}
