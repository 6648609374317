import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import {convertBlobToDataUrl} from '../../util/BlobHelper';
import {environment} from '../../../environments/environment';

// Inspired by: https://github.com/brhoomjs/cap-image-cache/tree/main

export interface ImageCacheConfig {
  /**
   * Determines if the query string should be part of the cache key (e.g. if a different query param should lead to a different cache entry)
   */
  includeQueryString?: boolean;
}

export const CACHE_PATH = new InjectionToken<string>('CACHE_PATH');
export const MAX_CACHE_AGE_MS = new InjectionToken<string>('MAX_CACHE_AGE_MS');

export interface ImageCacheResult {
  data?: string;
  from?: 'cache' | 'network';
}

interface CacheMeta {
  url: string;
  mime: string;
  expires?: number;
}

@Injectable({providedIn: 'any'})
export class ImageCacheService {

  private runningRetrievals: { [key: string]: Promise<ImageCacheResult> } = {};

  constructor(
    @Inject(CACHE_PATH) private cachePath: string = 'CACHE_IMAGES',
    @Inject(MAX_CACHE_AGE_MS) private maxCacheAgeMs: number = 1000 * 60 * 60 * 24 * 7, // 7 days
  ) {
  }


  public async getImageSrc(_url: string, config: ImageCacheConfig): Promise<ImageCacheResult> {

    if (!_url.startsWith('http:') && !_url.startsWith('https:')) {
      // We only cache http and https images, because if the file is already local, it does not make sense to cache it
      return {
        data: _url,
        from: 'network',
      };
    }

    if (environment.disableImageCache) {
      return {
        data: _url,
        from: 'network',
      };
    }

    // We use a hash of the image path (excluding the protocol
    const cacheFilename = await this.getCacheFilename(_url, config);

    try {
      const readMetaFile = await Filesystem.readFile({
        directory: Directory.Cache,
        path: this.cachePath + '/' + cacheFilename + '.meta',
        encoding: Encoding.UTF8,
      });
      const cacheMeta: CacheMeta = JSON.parse('' + readMetaFile.data);
      if ((cacheMeta.expires && cacheMeta.expires < Date.now()) || !cacheMeta.mime) {
        // We need to refresh the cache
        return await this.storeImage(_url, config);
      }

      const readFile = await Filesystem.readFile({
        directory: Directory.Cache,
        path: this.cachePath + '/' + cacheFilename,
      });
      return {
        data: `data:${cacheMeta.mime};base64,${readFile.data}`,
        from: 'cache',
      };
    } catch (e) {
      // If the file doesn't exist, we get an error, so we're gonna actually download it and store it in the cache.
      if (Object.keys(this.runningRetrievals).includes(cacheFilename)) {
        // If we're already retrieving this file, we return the promise
        return this.runningRetrievals[cacheFilename];
      }
      // If we're not retrieving this file yet, we start retrieving it and store the promise
      const storeImagePromise = this.storeImage(_url, config);
      this.runningRetrievals[cacheFilename] = storeImagePromise;
      const result = await storeImagePromise;
      delete this.runningRetrievals[cacheFilename];
      return result;
    }
  }

  private async getCacheFilename(_url: string, config: ImageCacheConfig): Promise<string> {
    // Hash the URL
    const url = new URL(_url);
    const path = url.host + '/' + url.pathname + (config.includeQueryString ? url.search : '');
    return await this.hashString(path);
  }

  private async hashString(path: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(path);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    return hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  }

  private async storeImage(
    url: string,
    config: ImageCacheConfig = {}
  ): Promise<ImageCacheResult> {

    const response = await fetch(url);
    const blob = await response.blob();
    if (blob.type === 'text/html') {
      throw new Error('There was an error while loading an image');
    }
    const base64Data = await convertBlobToDataUrl(blob);
    const cacheFilename = await this.getCacheFilename(url, config);
    await Filesystem.writeFile({
      directory: Directory.Cache,
      data: base64Data,
      path: this.cachePath + '/' + cacheFilename,
      recursive: true,
    });
    const cacheMeta: CacheMeta = {
      url: url,
      mime: blob.type,
      expires: Date.now() + this.maxCacheAgeMs,
    };

    await Filesystem.writeFile({
      directory: Directory.Cache,
      data: JSON.stringify(cacheMeta),
      path: this.cachePath + '/' + cacheFilename + '.meta',
      encoding: Encoding.UTF8,
    });

    return {
      data: base64Data,
      from: 'network',
    };
  }

  public clearCache(): Promise<void> {
    return Filesystem.rmdir({
      directory: Directory.Cache,
      path: this.cachePath,
      recursive: true
    });
  }

  async preloadImage(url: string, config: ImageCacheConfig) {
    // The preload function waits until there are no active requests for any images, and then loads the requested image
    // This is useful for loading images that are not immediately visible, but will be visible soon.
    while (Object.values(this.runningRetrievals).length > 0) {
      await Promise.all(Object.values(this.runningRetrievals));
    }
    await this.getImageSrc(url, config);
  }
}
