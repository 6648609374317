import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent  implements OnInit, OnChanges {
  @Input() maxStars = 5;
  @Input() rating = 0;

  stars: boolean[] = Array(this.maxStars).fill(false);

  @Output() ratingChanged = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['maxStars'] || changes['rating']) {
      this.rebuildStars();
    }
  }

  private rebuildStars() {
    this.stars = [];
    for (let i = 0; i < this.maxStars; i++) {
      this.stars.push(this.rating > i);
    }
  }

  setRating(rating: number) {

    if (rating === 1 && this.rating === 1) {
      rating = 0;
    }
    this.rating = rating;
    this.rebuildStars();
    this.ratingChanged.emit(this.rating);
  }
}
