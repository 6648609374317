<div class="header safe-area-m-top">
  <ion-button class="close" (click)="onBackClicked($event)" role="button" fill="clear" color="dark" aria-label="Back Button">
    <ion-icon name="arrow-back"></ion-icon>
  </ion-button>
  <app-progress-indicator *ngIf="totalSteps > 0" [currentStep]="currentStep" aria-label="Progress Indicator"
                          [totalSteps]="totalSteps"></app-progress-indicator>
  <ion-button class="close" (click)="onCloseClicked($event)" role="button" fill="clear" color="dark" aria-label="Close Button">
    <ion-icon name="close" slot="icon-only"></ion-icon>
  </ion-button>
</div>
