import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent  implements OnInit {

  @Input() src?: string;
  constructor() { }

  ngOnInit() {}

}
