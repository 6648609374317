import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @Output() popupClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() popUpTitle: string = '';
  @Input() popUpMessage: string = '';
  isClosing = false;

  private backButtonSub?: Subscription

  constructor(private platform: Platform) {
  }

  ngOnInit() {
    history.pushState({
      modal : true,
      desc : 'Popup open'
    }, '');

    this.backButtonSub = this.platform.backButton.subscribeWithPriority(80, (processNextHandler) => {
      if (!this.isClosing) {
        this.close();
      } else {
        processNextHandler();
      }
    })
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
    this.backButtonSub?.unsubscribe();
  }

  @HostListener('window:popstate', ['$event'])
  close(): void {
    this.isClosing = true;
    setTimeout(() => {
      this.isClosing = false;
      this.popupClose.emit(true);
    }, 400);
  }

}
