/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewReportIntegrity } from './newReportIntegrity';
import { NewReportUndesirable } from './newReportUndesirable';


export interface NewReport { 
    reportType: NewReport.ReportTypeEnum;
    organizationUid: string;
    counselorUid?: string;
    canWeContact?: boolean;
    contactName?: string;
    contactFunction?: string;
    contactType?: NewReport.ContactTypeEnum;
    contactEmail?: string;
    contactPhone?: string;
    description?: string;
    logbookUids?: Array<string>;
    reportUndesirable?: NewReportUndesirable;
    reportIntegrity?: NewReportIntegrity;
}
export namespace NewReport {
    export type ReportTypeEnum = 'UNDESIRABLE' | 'INTEGRITY';
    export const ReportTypeEnum = {
        Undesirable: 'UNDESIRABLE' as ReportTypeEnum,
        Integrity: 'INTEGRITY' as ReportTypeEnum
    };
    export type ContactTypeEnum = 'EMAIL' | 'PHONE';
    export const ContactTypeEnum = {
        Email: 'EMAIL' as ContactTypeEnum,
        Phone: 'PHONE' as ContactTypeEnum
    };
}


