import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-drag-and-drop-file',
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss'],
})
export class DragAndDropFileComponent implements OnInit {
  @Output() fileDropped = new EventEmitter<File>();

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.add('dragover');
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('dragover');
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const dropZone = event.currentTarget as HTMLElement;
    dropZone.classList.remove('dragover');

    if (event.dataTransfer?.files.length) {
      const file = event.dataTransfer.files[0];
      console.log('File:', file);
      console.log('File size:', file.size);
      if (file.type.startsWith('audio/') || file.type.startsWith('image/')) {
        this.fileDropped.emit(file);
      } else {
        alert('Only audio and image files are allowed.');
      }
    }
  }
}
