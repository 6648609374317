import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { BehaviorSubject, catchError, combineLatestWith, lastValueFrom, of, ReplaySubject, } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  locale: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.tryDetermineLanguage() ?? 'en'
  );
  backendLocale = new ReplaySubject<string>(1);

  constructor(
    private auth: AuthService,
    private user: UserService,
    private translate: TranslateService
  ) {
    // NgxTranslate can't detect which languages are available, so we have to tell it here
    this.translate.addLangs(['nl', 'en', 'es', 'pap']);
    this.locale.subscribe((lang) => {
      this.translate.use(lang);
    });
    this.loadProfile();
  }

  async setLanguage(lang: string, backendLocale: string) {
    this.locale.next(lang);
    this.backendLocale.next(backendLocale);
    await lastValueFrom(this.translate.use(lang));
  }

  tryDetermineLanguage(): string | undefined {
    let browserLangs = navigator?.languages;

    if (browserLangs && browserLangs.length > 0) {
      // Try to find a browser language that we support
      return browserLangs
        .map((browserLang) => {
          if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
          }

          if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
          }
          return browserLang;
        })
        .find((l) => this.translate.getLangs().includes(l));
    } else {
      return undefined;
    }
  }

  private loadProfile() {
    this.auth.isLoggedIn().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.tryUseLangFromBrowser();
        return;
      }

      this.auth
        .getMe()
        .pipe(
          catchError(() => {
            // Probably not logged in. It not too bad. But we fall back to the browser language then
            this.tryUseLangFromBrowser();
            return of(null);
          }),
          combineLatestWith(this.user.currentOrganization)
        )
        .subscribe(([user, organization]) => {
          if (user && organization) {
            const backendLocale = user.organizationUsers.find(
              (ou) => ou.organizationUid === organization.uid
            )?.localeCode;
            // The backend uses the (incorrect) language code "pa" for Papiamento. We use "pap" in the app (which is more correct)

            let language = backendLocale;
            if (language === 'pa') {
              language = 'pap';
            }
            if (!language || !backendLocale) {
              this.tryUseLangFromBrowser();
              return;
            }
            this.setLanguage(language, backendLocale).then();
            console.log(
              `Setting language to '${language}' based on logged in user`
            );
          }
        });
    });
  }

  private tryUseLangFromBrowser() {
    const lang = this.tryDetermineLanguage();
    if (lang) {
      this.translate.use(lang);
    }
  }
}
