import {Component, OnInit} from '@angular/core';
import {ScrollService} from "../shared/services/scroll.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms.page.html',
  styles: [],
})
export class TermsPage implements OnInit {
  constructor(private scroll: ScrollService, private translate: TranslateService) {
  }


  handleScroll(event: any) {
    this.scroll.currentScrollPosition.next(event.detail.scrollTop);
  }

  ngOnInit() {
    this.scroll.currentSelectedMenuTitle.next('');
  }
}
