<div [ngClass]="{ 'radio-option-active': selected, 'small': size === 'small', 'grayed-out': disabled }"
     class="radio-option"
     role="radio"
>
  <div (click)="selectOption()" class="main-radio flex-grow-1">
    <div [ngClass]="showAsSquare ? 'square' : 'circle'"></div>
    <div class="radio-option-content">
      <div class="info-item">
        <div [ngClass]="{'w-100': (!info && !isWeb()), 'w-fit' : isWeb()}" class="label">
          {{ label }}
        </div>
      </div>
      <p>{{ shortDescription }}</p>
    </div>
  </div>
  <div (click)="showInfoPopup()" *ngIf="info" class="radio-info">
    <ion-icon aria-label="Info" class="info-button" color="primary" name="information-circle-outline"
              role="button"></ion-icon>
  </div>
</div>

<app-popup (popupClose)="hideInfoPopup()" *ngIf="popupShown" [popUpMessage]="info!" [popUpTitle]="label"></app-popup>
