<div class="gray-selector" aria-label="Gray Selector">
  <div [ngClass]="{ 'selector-active': this.selected ===  this.value1 }"
       aria-label="Selector 1"
       (click)="setValue(this.value1)"
  >
    {{ label1 }}
  </div>
  <div
    [ngClass]="{ 'selector-active': this.selected ===  this.value2 }"
    aria-label="Selector 2"
    (click)="setValue(this.value2)"
  >
    {{ label2 }}
  </div>
</div>
