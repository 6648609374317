import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TermsComponent } from './terms/terms.component';
import { TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './menu/menu.component';
import { IonicModule } from '@ionic/angular';
import { RouterLink } from '@angular/router';
import { FileSizePipe } from './file-size.pipe';
import { MediaViewerComponent } from './media-viewer/media-viewer.component';
import { CtaComponent } from './cta/cta.component';
import { StepperHeaderComponent } from './stepper-header/stepper-header.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { RadiogroupComponent } from './radiogroup/radiogroup.component';
import { RadioComponent } from './radiogroup/radio/radio.component';
import { PopupComponent } from './popup/popup.component';
import { VideoViewerComponent } from './video-viewer/video-viewer.component';
import { CoreModule } from '../core/core.module';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { ToggleSelectorComponent } from '../report/shared/toggle-selector/toggle-selector.component';
import { TranslateOverridePipe } from './translate-override.pipe';
import { HcaptchaComponent } from './hcaptcha/hcaptcha.component';
import { FileSelectButtonComponent } from './file-select-button/file-select-button.component';
import { DragAndDropFileComponent } from './drag-and-drop-file/drag-and-drop-file.component';

@NgModule({
  declarations: [
    TermsComponent,
    MenuComponent,
    FileSizePipe,
    MediaViewerComponent,
    CtaComponent,
    ProgressIndicatorComponent,
    StepperHeaderComponent,
    RadiogroupComponent,
    RadioComponent,
    PopupComponent,
    VideoViewerComponent,
    StarRatingComponent,
    ToggleSelectorComponent,
    TranslateOverridePipe,
    TermsComponent,
    HcaptchaComponent,
    FileSelectButtonComponent,
    DragAndDropFileComponent,
  ],
  exports: [
    TermsComponent,
    MenuComponent,
    FileSizePipe,
    MediaViewerComponent,
    CtaComponent,
    ProgressIndicatorComponent,
    StepperHeaderComponent,
    RadiogroupComponent,
    RadioComponent,
    PopupComponent,
    VideoViewerComponent,
    StarRatingComponent,
    ToggleSelectorComponent,
    TranslateOverridePipe,
    HcaptchaComponent,
    FileSelectButtonComponent,
    DragAndDropFileComponent,
  ],
  imports: [CommonModule, TranslateModule, IonicModule, RouterLink, CoreModule, NgOptimizedImage],
})
export class SharedModule {}
