/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportInvolvedPartyView } from './reportInvolvedPartyView';


export interface UndesirableReportNoteUpdate { 
    counselorName?: string;
    hierarchicalPosition?: UndesirableReportNoteUpdate.HierarchicalPositionEnum;
    frequency?: UndesirableReportNoteUpdate.FrequencyEnum;
    period?: UndesirableReportNoteUpdate.PeriodEnum;
    workEnvWhere?: string;
    reporterActions?: Array<UndesirableReportNoteUpdate.ReporterActionsEnum>;
    actionReporterComment?: string;
    remarks?: string;
    previousComplaints?: boolean;
    previousComplaintsRemarks?: string;
    reporterPerson?: ReportInvolvedPartyView;
    accusedPerson?: ReportInvolvedPartyView;
}
export namespace UndesirableReportNoteUpdate {
    export const HierarchicalPositionEnum = {
        Above: 'ABOVE',
        Equal: 'EQUAL',
        Below: 'BELOW'
    } as const;
    export type HierarchicalPositionEnum = typeof HierarchicalPositionEnum[keyof typeof HierarchicalPositionEnum];
    export const FrequencyEnum = {
        OneOff: 'ONE_OFF',
        Occasionally: 'OCCASIONALLY',
        Weekly: 'WEEKLY',
        Daily: 'DAILY'
    } as const;
    export type FrequencyEnum = typeof FrequencyEnum[keyof typeof FrequencyEnum];
    export const PeriodEnum = {
        LessThanMonth: 'LESS_THAN_MONTH',
        OneToTwoMonths: 'ONE_TO_TWO_MONTHS',
        ThreeToSixMonths: 'THREE_TO_SIX_MONTHS',
        SixToYear: 'SIX_TO_YEAR',
        OneYearLonger: 'ONE_YEAR_LONGER'
    } as const;
    export type PeriodEnum = typeof PeriodEnum[keyof typeof PeriodEnum];
    export const ReporterActionsEnum = {
        MadeClear: 'MADE_CLEAR',
        NothingSaid: 'NOTHING_SAID',
        OtherNamely: 'OTHER_NAMELY',
        ReportedSick: 'REPORTED_SICK',
        SharedWithThirdParty: 'SHARED_WITH_THIRD_PARTY'
    } as const;
    export type ReporterActionsEnum = typeof ReporterActionsEnum[keyof typeof ReporterActionsEnum];
}


