/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateReport { 
    status?: UpdateReport.StatusEnum;
    counselorUid?: string;
}
export namespace UpdateReport {
    export type StatusEnum = 'DRAFT' | 'SENT' | 'READ' | 'CONTACTED' | 'RESOLVED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Sent: 'SENT' as StatusEnum,
        Read: 'READ' as StatusEnum,
        Contacted: 'CONTACTED' as StatusEnum,
        Resolved: 'RESOLVED' as StatusEnum
    };
}


