<div class="star-rating">
  <div *ngFor="let star of stars; let i = index"
       class="star"
       role="button"
       [attr.aria-label]="(i + 1) + ' stars'"
       (click)="setRating(i + 1)">
    <ion-icon name="star" *ngIf="star"></ion-icon>
    <ion-icon name="star-outline" *ngIf="!star"></ion-icon>
  </div>
</div>
