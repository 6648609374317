import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { isWeb } from '../../../util/IsWeb';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input({ required: true }) label: string = '';
  @Input() value: any;

  @Input() shortDescription?: string;
  @Input() info?: string;

  @Input() selected = false;
  @Input() size: 'small' | 'large' = 'large';
  @Input() disabled = false;
  @Input() showAsSquare = false;
  onSelected = new EventEmitter<boolean>();

  popupShown = false;
  protected readonly isWeb = isWeb;

  constructor() {}

  ngOnInit() {}

  showInfoPopup() {
    this.popupShown = true;
  }

  hideInfoPopup() {
    this.popupShown = false;
  }

  selectOption() {
    if (this.disabled) return;
    this.onSelected.emit(!this.selected);
  }
}
