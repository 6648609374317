/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionOptionView } from './questionOptionView';
import { QuestionTranslationView } from './questionTranslationView';
import { MediaView } from './mediaView';


export interface QuestionView { 
    uid: string;
    questionnaireUid: string;
    organizationUid: string;
    sortOrder: number;
    type: QuestionView.TypeEnum;
    translations: Array<QuestionTranslationView>;
    options: Array<QuestionOptionView>;
    media?: Array<MediaView>;
    embedUrl?: string;
}
export namespace QuestionView {
    export const TypeEnum = {
        MultipleChoice: 'MULTIPLE_CHOICE',
        SingleChoice: 'SINGLE_CHOICE',
        FiveStarRating: 'FIVE_STAR_RATING',
        Open: 'OPEN',
        TenStarRating: 'TEN_STAR_RATING',
        TextOnly: 'TEXT_ONLY'
    } as const;
    export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];
}


