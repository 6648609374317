/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportInvolvedPartyView { 
    name?: string;
    gender?: ReportInvolvedPartyView.GenderEnum;
    age?: number;
    function?: string;
    department?: string;
    type: ReportInvolvedPartyView.TypeEnum;
}
export namespace ReportInvolvedPartyView {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type TypeEnum = 'INDIVIDUAL' | 'GROUP' | 'ORGANIZATION';
    export const TypeEnum = {
        Individual: 'INDIVIDUAL' as TypeEnum,
        Group: 'GROUP' as TypeEnum,
        Organization: 'ORGANIZATION' as TypeEnum
    };
}


