/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomPushNotificationView { 
    uid: string;
    subject: CustomPushNotificationView.SubjectEnum;
    title: string;
    body: string;
    resourceUrl: string;
    organizationUid: string;
    createdAt: string;
    scheduledAt?: string;
}
export namespace CustomPushNotificationView {
    export const SubjectEnum = {
        Questionnaire: 'QUESTIONNAIRE',
        ContentPage: 'CONTENT_PAGE',
        Sos: 'SOS',
        Custom: 'CUSTOM'
    } as const;
    export type SubjectEnum = typeof SubjectEnum[keyof typeof SubjectEnum];
}


