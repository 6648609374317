import { inject } from '@angular/core';
import { AuthService, AuthState } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { NavController } from '@ionic/angular';
import { isWeb } from '../util/IsWeb';
import { NavRestoreService } from '../core/services/nav-restore.service';

/**
 * Direct the user to the correct page depending on the current auth state. i.e.:
 * If the app is not yet activated, redirect to /setup/get-started
 * If the app is in setup mode, redirect to /setup/set-password
 * If the user is not logged in, redirect to /login
 * If the user is logged in, redirect to /
 * @param allowedAuthStates
 */
export function authStateGuard(...allowedAuthStates: (AuthState | 'dev')[]): Observable<boolean> {
  if (allowedAuthStates.includes('dev') && !environment.production) return of(true);

  const navRestore = inject(NavRestoreService);
  const auth = inject(AuthService);
  const nav = inject(NavController);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  return auth.state.pipe(
    // If we get an initializing state, we want to wait until we get a different state
    filter((state) => state !== AuthState.INITIALIZING),
    map((state) => {
      if (allowedAuthStates.includes(state)) return true;

      console.log('Auth state:', state);
      if (isWeb() && (state === AuthState.NOT_ACTIVATED || state === AuthState.NOT_LOGGED_IN)) {
        navRestore.storeNavState();
        if (environment.ssoLoginButtons && environment.ssoLoginButtons.length > 0) {
          nav.navigateRoot(['/auth/select-login-method']).then();
        } else {
          nav.navigateRoot(['/auth/login']).then();
        }
        return false;
      }

      switch (state) {
        case AuthState.NOT_ACTIVATED:
          nav.navigateRoot(['/setup/get-started']).then();
          break;
        case AuthState.IN_SETUP:
          nav.navigateRoot(['/setup/set-password']).then();
          break;
        case AuthState.CONFIGURE_PIN:
          nav.navigateRoot(['/setup/standalone/set-pin']).then();
          break;
        case AuthState.NOT_LOGGED_IN:
          nav.navigateRoot(['/auth/login']).then();
          break;
        case AuthState.REQUIRE_PIN:
          nav
            .navigateForward(['/auth/enter-pin-code'], {
              queryParamsHandling: 'merge',
              queryParams: {
                redirectUrl: router.getCurrentNavigation()?.finalUrl?.toString(),
              },
            })
            .then();
          break;
        case AuthState.LOGGED_IN:
        case AuthState.LOGGED_IN_WITH_PIN:
          const redirectUrl = activatedRoute.snapshot.queryParamMap.get('redirectUrl');
          if (redirectUrl) {
            nav.navigateRoot([redirectUrl]).then();
            break;
          }
          if (isWeb()) {
            const restoreTree = navRestore.restoreAsUrlTree();
            if (restoreTree) {
              console.log('Restoring navigation state:', restoreTree.toString());
              nav.navigateRoot(restoreTree).then();
              break;
            }
          }

          nav.navigateRoot(['/']).then();
          break;
        default:
          console.error('Unknown auth state: ' + state);
      }
      return false;
    })
  );
}
