import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

type NavState = {
  url: string;
  timestamp: number;
};

/**
 * This service is used to restore the navigation state after the user is redirected somewhere, for example because he/she is no longer authenticated.
 */
@Injectable({
  providedIn: 'root',
})
export class NavRestoreService {
  private readonly STORAGE_KEY = 'nav_restore_state';

  private currentTargetUrl?: string;
  private initialized = false;

  constructor(private router: Router) {
    this.initialize();
  }

  /**
   * Stores the current page URL
   * @param route The current ActivatedRoute (not actually needed)
   */
  storeNavState(route?: RouterStateSnapshot): void {
    const currentUrl = route?.url ?? this.currentTargetUrl;

    if (!currentUrl) {
      console.warn(
        'No URL to store. If you are using this method outside of a navigation event, make sure to pass the RouterStateSnapshot as an argument.',
      );
      return;
    }

    const navState: NavState = {
      url: currentUrl,
      timestamp: new Date().getTime(),
    };

    // Store in sessionStorage
    sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(navState));
  }

  /**
   * Retrieves the stored URL and creates a UrlTree
   * @returns A UrlTree representing the stored URL, or null if no state is stored
   */
  restoreAsUrlTree(): UrlTree | null {
    const stateJson = sessionStorage.getItem(this.STORAGE_KEY);

    if (!stateJson) {
      return null;
    }

    try {
      const state = JSON.parse(stateJson) as NavState;
      this.clearNavState();
      // Parse the stored URL into a UrlTree
      return this.router.parseUrl(state.url);
    } catch (error) {
      console.error('Error restoring navigation state:', error);
      this.clearNavState();
      return null;
    }
  }

  /**
   * Restores the navigation state and navigates to the stored URL
   * @returns Promise<boolean> Result of the navigation attempt
   */
  restoreAndNavigate(): Promise<boolean> {
    const urlTree = this.restoreAsUrlTree();

    if (urlTree) {
      // Clear state before navigating to prevent navigation loops
      return this.router.navigateByUrl(urlTree);
    }

    return Promise.resolve(false);
  }

  /**
   * Clears the stored navigation state
   */
  clearNavState(): void {
    sessionStorage.removeItem(this.STORAGE_KEY);
  }

  /**
   * Sets up router event listeners to capture navigation intentions
   */
  initialize(): void {
    if (this.initialized) return;

    // Listen for NavigationStart events which occur BEFORE guards run
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationStart)) {
        return;
      }

      this.currentTargetUrl = event.url;
    });

    this.initialized = true;
  }
}
