<div [ngClass]="isScrolling && isSmallScreen ? 'show-bg':''" class="info-menu ">
  <div class="header safe-area-m-top">
    <div *ngIf="!showBackButton" class="logo">
      <img *ngIf="isScrolling && isSmallScreen" alt="Report App" src="/assets/img/logo.png">
    </div>
    <div (click)="goBack()" *ngIf="showBackButton" class="back-button" role="button">
      <ion-icon [size]="'large'" name="arrow-back"></ion-icon>
    </div>
    <div class="title">
      <h3 *ngIf="isScrolling && isSmallScreen">{{ menuTitle }}</h3>
    </div>
    <div (click)="showMenuDetail = !showMenuDetail"
         *ngIf="showHamburger"
         [ngClass]="{ 'black-hamburger': isScrolling && isSmallScreen, 'hamburger-position-adjust': isSmallScreen && !isScrolling, 'large-screen': !isSmallScreen }"
         aria-label="Open menu"
         class="hamburger" role="button"
    >
      <span class="line line-1"></span>
      <span class="line line-2"></span>
      <span class="line line-3"></span>
    </div>
  </div>
</div>

<div [ngClass]="{ 'hamburger-bg-position-adjust': isScrolling && isSmallScreen }" class="hamburger-bg-icon">
  <!--  <img src="../../../assets/icon/hamburger-background.svg" alt="">-->
  <div *ngIf="!isScrolling" class="secret-gray-block safe-area-top"></div>
</div>

<div [ngClass]="showMenuDetail ? 'show-menu-detail' : ''" class="info-menu-detail">
  <div class="header">
    <div></div>
    <ion-button (click)="closeMenu()" class="close safe-area-p-top" color="dark" fill="clear">
      <ion-icon [size]="'large'" name="close" slot="icon-only"></ion-icon>
    </ion-button>
  </div>

  <div class="links">
    <div (click)="showMenuDetail = !showMenuDetail" [routerLink]="['/settings/profile']" aria-label="My Profile"
         class="menu-item"
         role="button">
      {{ 'General.MyProfile' | translate }}
    </div>
    <div (click)="showMenuDetail = !showMenuDetail" [routerLink]="['/settings/notifications']"
         aria-label="Notifications"
         class="menu-item"
         role="button"
    >
      {{ 'General.Notifications' | translate }}
    </div>
    <div (click)="showMenuDetail = !showMenuDetail" *ngIf="userIsSecurity && sosEnabled"
         [routerLink]="['/settings/sos']" aria-label="SOS"
         role="button"
         class="menu-item">
      {{ 'General.Soss' | translate }}
    </div>
    <div [routerLink]="['/settings/about']" aria-label="About" class="menu-item" role="button">
      {{ 'General.AboutReportApp' | translate }}
    </div>
    <div [routerLink]="['/feedback']" aria-label="Send Feedback" class="menu-item" role="button">
      {{ 'General.Feedback' | translate }}
    </div>

    <div [routerLink]="'/terms'" aria-label="Terms" class="menu-item" role="button">
      {{ 'General.Terms' | translate }}
    </div>

    <div (click)="logout(); showMenuDetail = !showMenuDetail" aria-label="Logout" class="menu-item grayed-out" role="button">
      {{ 'General.Logout' | translate }}
    </div>
  </div>
  <div class="socials">
    <div class="menu-item">
      <a href="https://www.linkedin.com/company/report-app-bv/?originalSubdomain=nl" target="_blank">
        <!--suppress AngularNgOptimizedImage Doesn't work properly with SVG-->
        <img alt="" src="/assets/icon/linkedin.svg">
      </a>
    </div>
  </div>
</div>
