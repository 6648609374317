/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateQuestionnaireInstance { 
    status?: UpdateQuestionnaireInstance.StatusEnum;
    start?: string;
    end?: string;
}
export namespace UpdateQuestionnaireInstance {
    export type StatusEnum = 'PENDING' | 'ACTIVATED' | 'EXPIRED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Activated: 'ACTIVATED' as StatusEnum,
        Expired: 'EXPIRED' as StatusEnum
    };
}


