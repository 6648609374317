import { inject } from '@angular/core';
import { AuthService, AuthState } from './auth.service';
import { Router, UrlTree } from '@angular/router';
import { filter, map, Observable } from 'rxjs';

export function loggedInGuard(): Observable<boolean | UrlTree> {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.state.pipe(
    filter(state => state !== AuthState.INITIALIZING),
    map((state) => {
      if (state === AuthState.LOGGED_IN || state === AuthState.LOGGED_IN_WITH_PIN) return true;
      if (state === AuthState.REQUIRE_PIN) {
        console.log(`auth state ${state}, redirecting to /setup/enter-pin-code`);
        return router.createUrlTree(['/auth/enter-pin-code'], {
          queryParamsHandling: 'merge',
          queryParams: {
            redirectUrl: router.getCurrentNavigation()?.finalUrl?.toString() // activatedRoute.snapshot.pathFromRoot.map(p => p.url.join('/')).join('/'),
          },
        })
      }
      if (state === AuthState.NOT_LOGGED_IN) {
        console.log('app not logged in, redirecting to /login');
        return router.createUrlTree(['/auth/login']);
      } else {
        console.log(`auth state ${state}, redirecting to /setup/get-started`);
        return router.createUrlTree(['/setup/get-started']);
      }
    }));
}
