import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map, Observable, Subscription, take, tap, } from 'rxjs';
import { AuthService } from './auth.service';
import { CommunicatorV2Service } from '../api/communicatorV2.service';
import { OrganizationUserView, OrganizationView, UpdateOrganizationUserMe, UpdateUserMe, UserMeView, } from '../generated-api';
import { IonicStorageService } from './ionic-storage.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  lastUserUpdate: number = 0;
  user = new BehaviorSubject<UserMeView | undefined>(undefined);
  currentOrganization: BehaviorSubject<OrganizationView | null> =
    new BehaviorSubject<OrganizationView | null>(null);

  private initialized = new BehaviorSubject(false);
  private lastSelectedOrganizationUid?: string;
  private lastSelectedOrganizationLoaded = false;

  private loggedInUserSubscription?: Subscription;

  private userSubscription?: Subscription;

  constructor(
    private comV2: CommunicatorV2Service,
    private authService: AuthService,
    private storage: IonicStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.authService.loggedInUser
      .pipe(filter(Boolean), take(1))
      .subscribe((authUser) => {
        if (authUser) {
          this.reloadUser();
        }
      });

    this.storage.get('lastSelectedOrganizationUid').then((uid) => {
      if (uid) {
        this.lastSelectedOrganizationUid = uid;
      }
      this.lastSelectedOrganizationLoaded = true;
      this.loadInitialOrganization();
    });

    this.userSubscription = this.user.subscribe((_) => {
      this.lastUserUpdate = Date.now();
      this.loadInitialOrganization();
    });
  }

  ngOnDestroy() {
    this.loggedInUserSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  loadInitialOrganization() {
    if (this.user.value === undefined || !this.lastSelectedOrganizationLoaded)
      return;

    this.getOrganizations().subscribe((organizations) => {
      if (organizations.length === 0) {
        console.warn('User has no organizations?');
        this.currentOrganization.next(null);
        return;
      }

      // If the user selected an organization before, we select that one
      if (this.lastSelectedOrganizationUid) {
        const previouslySelectedOrganization = organizations.find(
          (o) => o.uid === this.lastSelectedOrganizationUid
        );
        if (previouslySelectedOrganization) {
          this.selectCurrentOrganization(previouslySelectedOrganization);
          return;
        }
      }

      // Otherwise, we select the first organization
      this.selectCurrentOrganization(organizations[0]);
    });
  }

  updateUserMe(data: UpdateUserMe): Observable<UserMeView> {
    return this.comV2.updateUserMe(data);
  }

  updateOrganizationUserMe(
    data: UpdateOrganizationUserMe,
    orgUid: string
  ): Observable<OrganizationUserView> {
    return this.comV2.updateOrganizationUserMe(orgUid, data);
  }

  getOrganizationUser(
    orgUid: string,
    userUid: string
  ): Observable<OrganizationUserView> {
    return this.comV2.getOrganizationUser(orgUid, userUid);
  }

  getOrganizations(): Observable<OrganizationView[]> {
    return this.comV2.getOrganizations();
  }

  selectCurrentOrganization(organization: OrganizationView) {
    if (organization.uid === this.currentOrganization.value?.uid) return;

    this.currentOrganization.next(organization);
    this.lastSelectedOrganizationUid = organization.uid;
    this.storage.set('lastSelectedOrganizationUid', organization.uid).then();
    this.setOrganizationColor(organization.themeColor);
  }

  setOrganizationColor(color: string | undefined) {
    if (!color || color === '')
      this.document.documentElement.style.setProperty(
        '--ion-color-company',
        '#5F49E9'
      );
    this.document.documentElement.style.setProperty(
      '--ion-color-company',
      color!
    );
  }

  getUser(reloadForMedia: boolean = false): Observable<UserMeView> {
    if (this.initialized.value) {
      // Media only survives for 3 hours, so in order for it to still work, we can only use a user that is at most 3 hours old
      if (
        reloadForMedia &&
        this.lastUserUpdate !== 0 &&
        this.lastUserUpdate <
        Date.now() - (Date.now() - (3 * 60 * 60 * 1000 - 1000))
      ) {
        console.log('Reloading user for media');
        return this.reloadUser();
      }
      return this.user.pipe(
        filter((u) => !!u),
        map((u) => u!),
        take(1)
      );
    } else {
      return this.loadUser();
    }
  }

  getOrganizationFeatureEnabled(featureCode: string): Observable<boolean> {
    return this.currentOrganization.pipe(
      filter(
        (org): org is OrganizationView => org !== null && org !== undefined
      ),
      take(1),
      map((org: OrganizationView) => {
        return org.features.some((f) => f.code === featureCode);
      })
    );
  }

  reloadUser(): Observable<UserMeView> {
    const res = this.loadUser();
    // We want to be able to call this method while ignoring the response, so we subscribe to it and then return it
    res.subscribe();
    return res;
  }

  userHasSecurityRole() {
    return combineLatest([
      this.user.pipe(filter(Boolean)),
      this.currentOrganization.pipe(filter(Boolean)),
    ]).pipe(
      take(1),
      map(([user, currentOrganization]) =>
        user.organizationUsers.some(
          (ou) =>
            ou.organizationUid === currentOrganization?.uid &&
            ou.roles.includes('ROLE_SECURITY')
        )
      )
    );
  }

  private loadUser() {
    this.initialized.next(true);
    return this.comV2.getUserMe().pipe(tap((user) => this.user.next(user)));
  }
}
