import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenManagerService } from './token-manager.service';

export const NO_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenManager: TokenManagerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(NO_AUTH)) {
      return next.handle(req);
    }

    return from(this.handle(req, next));
  }

  private async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const token = await this.tokenManager.getAccessToken();
    if (token) {
      //check if request includes an "unauthenticated" endpoint, if so don't add the token
      if (req.url.includes('unauthenticated')) {
        return await lastValueFrom(next.handle(req));
      }

      //this is for any other authenticated requests
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });

      try {
        return await lastValueFrom(next.handle(cloned));
      } catch (e) {
        console.error('Error handling web request', e);

        if (
          e instanceof HttpErrorResponse &&
          (e.status == 401 || e.status == 403)
        ) {
          this.tokenManager.clearAccessToken();
          // this.tokenManager.clearRefreshToken();
        }

        throw e;
      }
    } else {
      //this is only for new user registration setup
      if (req.url.includes('activation/register')) {
        const activationToken = this.tokenManager.getActivationToken();
        const cloned = req.clone({
          headers: req.headers.set(
            'Authorization',
            'Bearer ' + activationToken
          ),
        });
        return await lastValueFrom(next.handle(cloned));
      } else {
        return await lastValueFrom(next.handle(req));
      }
    }
  }
}
