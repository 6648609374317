/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IntegrityResolutionView { 
    reportUid: string;
    resolution: IntegrityResolutionView.ResolutionEnum;
    remarks: string;
    postResolutionNotes?: string;
}
export namespace IntegrityResolutionView {
    export type ResolutionEnum = 'INVESTIGATED_NO_ACTION_TAKEN' | 'INVESTIGATED_ACTION_TAKEN' | 'NOT_INVESTIGATED' | 'REFERRED' | 'OTHER';
    export const ResolutionEnum = {
        InvestigatedNoActionTaken: 'INVESTIGATED_NO_ACTION_TAKEN' as ResolutionEnum,
        InvestigatedActionTaken: 'INVESTIGATED_ACTION_TAKEN' as ResolutionEnum,
        NotInvestigated: 'NOT_INVESTIGATED' as ResolutionEnum,
        Referred: 'REFERRED' as ResolutionEnum,
        Other: 'OTHER' as ResolutionEnum
    };
}


