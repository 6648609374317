/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MediaView { 
    id: string;
    uid: string;
    url: string;
    name: string;
    mime: string;
    permission: MediaView.PermissionEnum;
    duration?: number;
    size: number;
    cache?: number;
    width?: number;
    height?: number;
    processing: boolean;
}
export namespace MediaView {
    export type PermissionEnum = 'SHARED' | 'ORGANIZATION' | 'LOGBOOK' | 'REPORT_NOTE' | 'CONTENT_PAGE' | 'QUESTIONNAIRE';
    export const PermissionEnum = {
        Shared: 'SHARED' as PermissionEnum,
        Organization: 'ORGANIZATION' as PermissionEnum,
        Logbook: 'LOGBOOK' as PermissionEnum,
        ReportNote: 'REPORT_NOTE' as PermissionEnum,
        ContentPage: 'CONTENT_PAGE' as PermissionEnum,
        Questionnaire: 'QUESTIONNAIRE' as PermissionEnum
    };
}


