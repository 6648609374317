/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocaleView } from './localeView';
import { CustomContentPageCategoryView } from './customContentPageCategoryView';
import { ContentPageView } from './contentPageView';


export interface OrganizationContentPageTranslationView { 
    id: string;
    contentPage: ContentPageView;
    locale: LocaleView;
    title: string;
    preview?: string;
    pinned: boolean;
    estimatedReadSeconds?: number;
    customCategory?: CustomContentPageCategoryView;
    sortOrder: number;
}

