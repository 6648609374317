/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationView } from './organizationView';
import { MediaView } from './mediaView';


export interface ContentPageView { 
    uid: string;
    label: string;
    category: string;
    ownerOrganization: OrganizationView;
    shared: boolean;
    availableLanguages: Array<string>;
    feedIcon?: MediaView;
    createdAt: string;
    updatedAt: string;
    pageType: ContentPageView.PageTypeEnum;
    basedOn?: string;
    contentMediumType: ContentPageView.ContentMediumTypeEnum;
    madeByReportApp: boolean;
    isDefault: boolean;
}
export namespace ContentPageView {
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE',
        Organization: 'ORGANIZATION',
        FeedOnly: 'FEED_ONLY',
        All: 'ALL'
    } as const;
    export type PageTypeEnum = typeof PageTypeEnum[keyof typeof PageTypeEnum];
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE',
        Podcast: 'PODCAST',
        Video: 'VIDEO'
    } as const;
    export type ContentMediumTypeEnum = typeof ContentMediumTypeEnum[keyof typeof ContentMediumTypeEnum];
}


